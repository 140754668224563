export default {
    meds: 'داروها',
    hygProds: 'محصولات بهداشتی',
  baseAuth: {
    later: 'بعداً',
  },
  preSignup: {
    title: 'برای اینکه داروها و محصولات بهداشتی را دریافت کنی، لازم است که یک حساب با استفاده از یک آدرس ایمیل درست بکنی  نیازی به ارائه اطلاعات بیشتر نخواهی داشت .',
    agree: 'تأیید',
  },
  signup: {
    title: 'باز کردن حساب جدید',
    repeatPassword: 'بازنویسی رمز عبور',
    submit: 'ثبت نام',
    agreeToTermsOfUse: 'با باز کردن یک حساب ، شما به طور رسمی با',
    termsOfUse: 'شرایط و مقرر',
  },
  login: {
    title: 'ورود به حساب',
    email: 'آدرس ایمیل',
    password: 'رمز عبور',
  },
  forgotPassword: {
    title: 'آیا شما رمز عبور خود را فراموش کرده اید؟',
    text: 'آدرس ایمیل خود را وارد کنید تا ما بتوانیم حساب شما را پیدا کنیم. دستورالعمل ورود دوباره شما به حسابتان از طریق ایمیل به شما ارسال خواهد شد.',
    cancel: 'لغو کنید',
    submit: 'ارسال',
    successText: 'دستورالعمل بازیابی رمز عبور به آدرس ایمیل شما ارسال شد. در صورتی که شما ایمیلی دریافت نکرده اید، اطمینان حاصل کنید که آدرس ایمیلی که وارد کرده اید با آدرس ایمیل ثبت شده در سایت ما یکسان می باشد. همچنین پوشه مربوط به ایمل های اسپم خود را نیز چک کنید.',
  },
  resetPassword: {
    title: 'تعویض رمز عبور',
    password: 'رمز عبور',
    repeatPassword: 'تکرار رمز عبور',
    submit: 'تایید',
  },
  rules: {
    requiredField: 'لازم است این قسمت پر شود',
    minLength: 'حد اقل ۳ کاراکتر را وارد نمایید.',
    passwordLength: 'رمز عبور شما باید دارای حد اقل ۸ کاراکتر باشد.',
    emailValidation: 'لطفا یک آدرس ایمیل صحیح وارد کنید.',
    passwordsNotMatch: 'رمز های عبور وارد شده با یکدیگر منطبق نمی باشند.',
    quantity: 'مقدار داروی وارد شده موجود نیست',
    invalidBirthYear: 'تاریخ تولد وارد شده نامعتبر است.',
  },
  profile: {
    title: 'پروفایل کاربر',
    email: 'آدرس ایمیل',
    language: 'زبان',
    appVersion: 'نسخۀ برنامه ‌ سازی',
    regionalUnities: {
      title: 'مناطق دریافت دارو ها و محصولات بهداشتی ',
      helpText: 'مناطق مورد نظر خود را انتخاب نمایید. در غیر این صورت، تمامی مناطق موجود در جستجو درج خواهند شد.',
    },
    sex: 'جنسیت',
    sexTypes: {
        male: 'مرد',
        female: 'زن',
        nonBinary: 'افرادی که هویت جنسیتی خود را منحصراً مرد یا زن نمی‌دانند',
        noneOfTheAbove: 'هیچکدوم از موارد بالا',
        noAnswer: 'ترجیح میدهم جواب ندهم',
    },
    birthYear: 'تاریخ تولد',
    changesSubmittedSuccessfully: 'تغییرات وارد شده با موفقیت به ثبت رسید.',
    notificationsEnabled: 'اعلان های دریافتی فعال شدند',
    notificationsDisabled: 'اعلان های دریافتی غیرفعال شدند',
    changePassword: {
      title: 'تعویض رمز عبور',
      currentPassword: 'رمز عبور فعلی',
      newPassword: 'رمز عبور جدید',
      repeatPassword: 'تکرار رمز عبور',
      submit: 'تایید',
      succesfulSubmittion: 'رمز عبور شما با موفقیت تعویض شد.',
    },
  },
  addNeeds: {
    title: 'درخواست خود را وارد کنید',
    byName: {
      title: 'جستجوی دارو ',
      header: 'نام داروی مورد نیاز خود را وارد کرده و آن را از بین گزینه های موجود در لیست انتخاب نمایید. ',
      autocompleteLabel: 'نام یا ماده فعال در دارو',
    },
    bySubstance: {
      title: 'جستجوی دارو ',
      header: 'نام دارو های مورد نیاز خود را وارد کرده و آنها را از لیست انتخاب کن.',
      autocompleteLabel: 'ماده دارویی',
    },
    save: 'داروی مورد نیازتان را به سفارش خود اضافه کنید.',
    quantityLabel: 'تعداد قوطی های مورد نیاز شما',
    quantityHygprodLabel: ' نیاز داری؟ {quantityType} چه تعداد',
    concentrationLabel: 'غلظت',
    concentrationPlaceholder: 'مثال: 50، 100، 200',
    concentrationUnitLabel: 'واحد',
    concentrationUnitPlaceholder: 'ML, G, MG :مثال',
    hygProds: {
        byName: {
            title: 'جستجوی محصولات بهداشتی',
            header: 'نام محصول بهداشتی یا زیر شاخه آن‌ را که می خواهی وارد کن و آن‌ را از لیست انتخاب کن.',
            autocompleteLabel: 'نام محصول بهداشتی یا زیر شاخه',
        },
        byCategory: {
            title: 'جستجوی دسته‌بندی و زیر شاخه ها',
            header: 'نام دسته بندی یا زیر شاخه ای که می خواهی را تایپ کن و آن را از لیست انتخاب کن.',
            autocompleteLabel: 'دسته بندی یا زیر شاخه محصولات بهداشتی ',
        },
    },
  },
  needsBasket: {
    title: 'محصولات برای سفارش',
    completeOrder: 'سفارش تکمیل شد',
  },
  needsPanel: {
    title: 'چه چیزی درخواست داده ام',
    emptyStatesMeds: 'نیاز دارویی وارد نشد. لطفاً نیاز دارویی خود را وارد نمایید تا داروهای قابل دسترس را برای شما پیدا کنیم.',
    emptyStatesHygProds: '!نیاز خود به یک محصول بهداشتی را وارد نکرده ای ، نیاز خود را واردکرده و در دسترس بودن آن را ببین',
    actions: {
      moreInfo: 'اطلاعات همراه با جزئیات',
      gotMed: 'دریافت شد',
      edit: 'در حال بررسی',
      delete: 'حذف',
      ok: 'تایید',
    },
    satisfyModal: {
      quantityText: 'مقدار مورد نیاز',
      successfulSatisfaction: 'مقدار تقاضای درخواستی با موفقیت انجام شد. ',
    },
    updateModal: {
      quantityText: 'چه تعداد قوطی احتیاج داری؟',
      successfulUpdate: 'مقدار مورد نیاز با موفقیت اطلاع داده شد.',
    },
    deleteModal: {
      areYouSure: 'آیا مطمئن هستی که می خواهی محصول مورد نظر را از لیست نیاز های خود حذف بکنی؟',
      successfulDeletion: 'عمل حذف با موفقیت انجام شد.',
    },
  },
  automatch: {
    title: 'نتیجه جست و جو',
    infoHeaders: [
      'موجودی داروها و محصولات بهداشتی که احتیاج داری را ببین.',
      'چک کن که آیا مدارک لازم را داری و داروها و محصولات بهداشتی را از نهاد عام المنفعه انتخابی خود دریافت کن.',
    ],
    noAutomatchMeds: 'هیچ دارویی برای رزرو کردن وجود ندارد طبق نیازهای صفحه وجود دارند "چه چیزی درخواست داده ام".',
    noAutomatchHygProds: 'محصولات بهداشتی برای رزرو کردن وجود ندارند، براساس نیازهایی که در صفحه وجود دارند  "چه چیزی درخواست داده ام".',
    emptyStatesNoNeeds: '   داروها و محصولات بهداشتی مورد نیاز خود را وارد کن و در ادامه پیدا خواهی کرد که از کدام نهاد‌ه عام المنفعه می توانی آنها را دریافت کنی.',
    emptyStatesNoAutomatch: 'در حال حاضر داروها و محصولات بهداشتی که دنبال آنها هستی در دسترس نمی باشند چند روز دیگر دوباره امتحان کن.',
    emptyStatesNoAutomatchOrNoNeeds: 'اینجا داروها و محصولات بهداشتی موجود و در دسترس را براساس نیازهایی که اعلام کرده ای پیدا خواهی کرد چنانچه نیازهای خود را وارد کرده ای و هیچ نتیجه ای حاصل نشده است ، چند روز دیگر دوباره امتحان کن.',
    map: 'نقشه',
    donationConditions: {
      title: 'مدارک',
      modal: {
        refugeeNote: 'اگر پناهنده هستی و در یک سازمان غیر دولتی ثبت نام کرده ای ، از آن سازمان کمک بگیر تا با نهاد عام المنفعه تماس بگیرد.',
        title: 'نیاز دارد تا به تو دارو و محصولات بهداشتی بدهد {organization} مدارکی که.',
      },
    },
    reserveProduct: {
        button: 'رزرو کردن',
        success: 'Τبرای دریافت میبایستی نهایتاً تا سه روز کاری مراجعه کنی  .(ایمیل ات را ببین)مطلع شد {orgName} داروخانه اجتماعی . مدارک لازم را همراه خود داشته باش.',
    },
  },
  satisfiedNeeds: {
    title: 'چه چیزی دریافت کرده ام',
    emptyStatesMeds: 'شما تا کنون دریافت هیچ دارویی را به ما اعلام نکرده اید. لطفاً به صفحه \'نیازهای دارویی\' مراجعه نمایید و در مورد داروهایی که موفق به دریافت آن شده اید به ما اطلاع دهید!',
    emptyStatesHygProds: 'تا کنون اعلام نکرده ای که چه محصول بهداشتی دریافت کرده ای ، به صفحه برو و آنجا اعلام کن ،چنانچه قوطی را دریافت کرده ای"چه چیزی دریافت کرده‌ام "',
  },
  map: {
    title: 'نقشۀ محل های دریافت دارو',
  },
  faq: {
    title: 'سوال های معمول',
    content: [
      {
        question: 'چگونه می توانم برای پرسیدن سوال درباره اپلیکیشن مِد فور یو با کسی تماس بگیرم؟',
        answer: 'برای تماس با GIVMED، سازمان غیرانتفاعی که MEDforU را ایجاد کرده است، یک ایمیل به آدرس hello@givmed.org ارسال کنید. یکی از اعضای تیم ما در اسرع وقت پاسخ خواهد داد. ساعات تماس: دوشنبه تا جمعه، 10:00 تا 17:00، زبان های گفتاری: یونانی و انگلیسی',
      },
      {
        question: 'برای دریافت دارو به کجا مراجعه کنم؟',
        answer: `<p>داروها توسط نهاد های عام المنفعه اهدا می شوند ، آدرس آنها را در اپلیکیشن در صفحه نتایج جستجو پیدا خواهی کرد  “MEDforU”.</p>
            <p> را بوجود آورده است ، دارو ذخیره یا توزیع نمی کند  MEDforUسازمان غیرانتفاعی است که Το GIVMED.</p>
        `,
    },
      {
        question: ' آیا برای دریافت دارو باید در یک سازمان غیر دولتی ثبت نام کنم؟',
        answer: `<p>پیدا کرده ای و در دسترس هستند را MEDforuایده آل تر آن است که لینک رسید داروهایت در نهاد عام المنفعه را که از طریق یک سازمان غیر دولتی پیگیری کند .</p>
            <p>اگر ثبت نام نشده ای ، با ما تماس بگیر تا یا یک سازمان غیر دولتی پیدا کنیم یا اینکه با یک نهاد عام المنفعه تماس بگیریم.</p>
            <p>در هر حالت باید نسخه پزشک داشته باشی .</p>
        `,
      },
      {
        question: 'آیا من به نسخه پزشک احتیاج دارم؟',
        answer: `<p>اگر به داروهای تجویز شده توسط دکتر نیاز داری ، نسخه پزشک ضروری است .</p>
            <p>اگر به داروهای تجویز نشده نیاز داری ، نسخه پزشک ضروری نیست .</p>
        `,
      },
    ],
  },
  tutorial: {
    title: 'دستور العمل',
    mainTitle: 'Do you want to learn step by step how to use our MEDforU app? Watch the video below!',
    iframe: {
        title: 'MEDforU tutorial video',
    },
  },
  terms: {
    title: 'شرایط و مقررات استفاده از سایت',
    content: [
      {
        header: 'شرایط و مقررات استفاده از سایت گیومِد (GIVEMED)',
        text: 'شرکت گیو مِد (GIVMED Share medicine share life) که از این پس به عنوان "این شرکت" از آن اسمبرده خواهد شد، در زمینه استفاده بهینه از داروهای باقی مانده یا مازاد شرکت های داروسازی برای استفاده گروه های آسیب پذیر اجتماع فعالیت می کند. اپلیکیشن MEDforU توسط این شرکت طراحی و مدیریت شده تا از این طریق راهی برای دستیابی گروه های آسیب پذیر اجتماع به داروهای مورد نیازشان فراهم سازد. استفاده از اپلیکیشن MEDforU، به معنی موافقت شما با شرایط و مقررات استفاده از این سایت که متن آن در پایین قید شده، می باشد. لطفاً متن آن را با دقت مطالعه نمایید، چرا که جهت استفاده از این اپلیکیشن، موافقت شما با شرایط و مقررات آن الزامی است. استفاده از این اپلیکیشن به معنی توافق شما با تمامی شرایط و مقررات استفاده از آن می باشد.',
      },
      {
        header: 'مالکیت معنوی',
        text: 'این اپلیکیشن و نیز تمامی اطلاعات، نشانه ها و یا لوگو های این شرکت، جملگی مالکیت معنوی این شرکت محسوب می شود که توسط قوانین ملی و نیز قوانین اروپایی مربوط به مالکت معنوی می باشد. (N.2121/1993, 2000/31/ΕΚ και 2001/29/ΕΚ) ',
      },
      {
        header: 'محافظت از اطلاعات شخصی',
        text: 'این شرکت بدین منظور طبق ماده N. 4624/2019 قانون 679/2016 ، توافقنامه 679/2016 اتحادیه اروپا در زمینه محافظت از اطلاعات شخصی و  مقررات مربوط به این قانون، پایبندی کامل خود را به محافظت از اطلاعات شخصی کاربر، در سطح عالی اعلام می کند. این شرکت وکالت قانونی خود در زمینه محافظت از اطلاعات شخصی در کلیه موارد مربوط به محافظت از اطلاعات شخصی کاربران این اپلیکیشن را به شخص اِلِنی دِده وکیل در آتن (ΑΜΔΣΑ: 34461) واگذار نموده به صورتی که در توافقنامه 679/2016 اتحادیه اروپا، درباره محافظت از اطلاعات شخصی تعریف شده است.',
      },
      {
        header: 'جمع آوری اطلاعات شخصی کاربر در طول ثبت نام در اپلیکیشن',
        text: 'این شرکت اطلاعات شخصی کاربر را بعد از گرفتن رضایت کامل وی که به طور صریح در جریان ثبت نام، بعد از دانلود اپلیکیشن انجام می شود، جمع آوری می نماید. کاربر پیش از ثبت نام در اپلیکیشن، به متن شرایط و مقررات دسترسی دارد. با دریافت موافقت صریح کاربر با این شرایط و مقررات و دریافت رضایت صریح وی، به جمع آوری اطلاعات شخصی کاربر که برای استفاده صحیح از اپلیکیشن نیاز است، اقدام می نماید. جمع آوری این اطلاعات برای استفاده از این اپلیکیشن در جهت ممکن ساختنِ اهدای داروها از طرف نهادهای اجتماعی مربوطه به کاربر، مورد نیاز می باشد. اطلاعات شخصی کاربر توسط خود شخص کاربر و با رضایت صریح وی در طول ثبت نام در اپلیکیشن طبق شرایط مذکور، واگذار می شود.',
      },
      {
        header: 'اطلاعات شخصی جمع آوری شده توسط ما',
        text: `در جریان ثبت نام، کاربر اطلاعات شخصی ذکر شده در پایین را وارد می کند:
آدرس ایمیل، جنسیت، سال تولد و محله ای که در آن به جست و جوی داروی مورد نیاز خود هستند. سپس کاربر نام دارویی که به دنبال دسترسی به آن است را وارد کرده و اطلاعات موجود درمورد در دسترس بودن آن دارو در نهادهای اجتماعی مربوطه را دریافت می نماید. اپلیکیشن اطلاعات مربوط به نیاز دارویی کاربر را ثبت می کند تا از این طریق کاربر را به نهادهای اجتماعی که از آنها می توانند داروی مورد نظر خود را دریافت کنند، متصل نماید. این شرکت تحت هیچ عنوان اطلاعاتی به غیر از موارد مذکور در بالا را از کاربر جمع آوری نمیکند. `,
      },
      {
        header: 'آرشیو کردن اطلاعات شخصی کاربران',
        text: `به طور خاص، این شرکت یک آرشیو کلی از اطلاعات داده شده مذکور در بالا را در مطابقت با ماده N. 4624/2019 قانون محافظت از اطلاعات اشخاص خاص و توافقنامه 679/2016 اتحادیه اروپا درباره محافظت از اطلاعات شخصی، نگهداری می کند. جهت نگهداری از این اطلاعات، این شرکت در مقابل نهاد مسئول برای اصل محافظت از داده های شخصی خود را معرفی نموده و کلیه اقدامات حقوقی و فنی لازم در راستای تأمین امنیت و محافظت از این اطلاعات را اعمال نموده است.
همچنین، این شرکت `,
      },
      {
        header: 'حقوق کاربر در ارتباط با اطلاعات شخصی',
        text: 'کاربر در هر زمان حق ویرایش، تغییر، تصحیح و یا درخواست پاک کردن کامل اطلاعات شخصی خود از اپلیکیشن را دارا می باشد. کاربر می تواند کلیه اقدامات مذکور را از طریق "فرم داده ها و اطلاعات شخصی" انجام دهد. برای هر سؤال و یا موضوع مربوط به جمع آوری، ویرایش و نگهداری از اطلاعات شخصی، کاربران می توانند به طور مستقیم با نهاد مسؤول محافظت از اطلاعات شخصی از طریق آدرس ایمیل مقابل، در ارتباط باشند. <a class=\'default-link\' href=\'mailto:dpo@givmed.org\'>dpo@givmed.org</a> این شرکت در صورت واقعه هرگونه تخلف و یا نقض در سیستم های امنیتی از طریق ارسال یک پیام مخصوص به طور مجزا به هریک از کاربران، آنها را ظرف ۲۴ ساعت از زمان مطلع شدن شرکت از واقعه، از آن مطلع می سازد. در این پیام، کلیه اطلاعات مربوط به درز شدن داده ها و نیز اقدامات امنیتی و تکنیکی انجام گرفته توسط شرکت را با جزئیات کامل در اختیار کاربر قرار می دهد.',
      },
      {
        header: 'استفاده از اطلاعات شخصی کاربر توسط شخص ثالث',
        text: 'اطلاعات شخصی که کاربر در طول ثبت نام و استفاده از اپلیکیشن GIVMED وارد می کند، به طور خاص برای اطمینان ساختن از داده شدن خدمات بهینه به کاربر استفاده می شود. به عبارت دیگر این اطلاعات شخصی تنها به منظور انجام خدمات رساندن داروهای اهدایی به افراد ذی نفع مورد استفاده قرار می گیرد. این اطلاعات مگر با رضایت کتبی شخص کاربر، به هیچ عنوان با شخص ثالث در میان گذاشته نخواهد شد. به اطلاع می رسانیم که ما می توانیم اطلاعات شخصی کاربران به منظور بهتر کردن خدمات مربوط به اهداف این اپلیکیشن، در اختیار شرکای این شرکت قرار دهیم. کاربر در هنگام تکمیل ثبت نام خود، با موافقت کردن با شرایط و مقررات اپلیکیشن، رضایت کتبی خاص و آزادانه خود را درمورد جمع آوری و آرشیو کردن اطلاعات شخصی مذکور توسط این شرکت در جهت خدمات و استفاده بهتر از اپلیکیشن، اعلام می نماید. البته همواره به منظور اعتراض به پردازش این اطلاعات از طریق دادن درخواست جهت، تصحیح، استفاده موقت، گرفتن تعهد، پاک کردن کامل و دائمی اطلاعات، طبق ماده N. 4624/2019 قانون 679/2016 ، توافقنامه 679/2016 اتحادیه اروپا در زمینه محافظت از اطلاعات شخصی، حق کاربر محفوظ می باشد. ',
      },
      {
        header: 'دسترسی به شبکه و دستگاه ها',
        text: 'همواره مسؤلیت دسترسی داشتن به شبکه و نیز دستگاه مورد نیاز و به روز سازی آنها در صورت لزوم برای استفاده از اپلیکیشن و خدمات مربوطه و همچنین به روز سازی نرم افزار های لازم با شخص کاربر می باشد. ',
      },
      {
        header: 'اطلاع رسانی',
        text: 'این شرکت می تواند از طریق اطلاعیه و با استفاده از آدرس ایمیل شما که در حساب کاربری شما موجود است، به شما اطلاع رسانی نماید. همچنین شما می توانید از طریق مکاتبه کتبی با آدرس شرکت، یونان - آتن - متاکسورگیو، خیابان ویکتور هوگو، پلاک ۱۵، و یا از طریق ایمیل با این شرکت در ارتباط باشید. آدرس ایمیل ما: <a class=\'default-link\' href=\'mailto:hello@givmed.org\'>hello@givmed.org</a>.',
      },
      {
        header: 'محدودیت در مسؤلیت',
        text: 'هدف این شرکت هماهنگ کردن روند در دسترس قرار دادن دارو از نهادهای اجتماعی مربوطه به کاربران از طریق این اپلیکیشن می باشد و بنابر این، هیچگونه مسئولیتی را در چهارچوب اهدای نهایی دارو که از طریق این اپلیکیشن هماهنگ شده، نمی پذیرد. همچنین، این شرکت در قبال صحت و یا عدم صحت اطلاعات داده شده توسط کاربر مسئولیتی نمی پذیرد. مسئولیت درستی داده های کاربر به طور تام بر عهده خود کاربر می باشد. در هر صورت، این شرکت در ارتباط با کیفیت داروهای اهدایی دادن هیچگونه ضمانتی را تقبل نمی کند و نیز هیچگونه مسئولیتی در ارتباط با عوارض جانبی داروها نمی پذیرد. در نهایت، این شرکت تحت هیچ شرایطی مسئولیت هرگونه ادعای حقوقی ، مدنی و یا جنایی یا هرگونه خسارت وارده از طرف کاربران اپلیکیشن یا اشخاص ثالث به هر دلیلی که مربوط به بهره برداری یا استفاده از این اپلیکیشن و یا روش اهدای داروها باشد را نخواهد پذیرفت.',
      },
      {
        header: 'شرایط لازم برای استفاده از اپلیکیشن',
        text: 'استفاده این اپلیکیشن توسط افراد زیر سن قانونی ۱۸ سال غیر مجاز می باشد. همانطور که به طور کتبی اعلام شده، این شرکت هیچگونه مسؤلیتی در مورد داده های اشتباه درباره سال تولد کاربر در زمان ثبت نام تقبل نکرده و در نهایت در صورت استفاده افراد زیر سن ۱۸ سال از این اپلیکیشن این شرکت هیچگونه مسؤلیتی را بر عهده نمی گیرد. ',
      },
      {
        header: 'صلاحیت قضایی',
        text: 'بدین طریق کاربر این اپلیکیشن می پذیرد که صلاحیت قضایی کامل در صورت هرگونه اختلاف نظر کاربر، با شرکت GIVMED در دادگاه های آتن و قوه قضایی کشور یونان می باشد.',
      },
    ],
  },
  contact: {
    title: 'ارتباط با ما',
  },
  about: {
    title: 'درباره داروها ياد بگير',
    content: [
        {
          question: 'What exactly is medicine?',
          answer: `<p>Medicine is substances, chemical or herbal, that have been scientifically proven to be effective and safe, and are taken or given to the body with the aim of:</p>
              <ul>
              <li>
                  The treatment of a disease or a pathological condition (e.g., antibiotics are taken to treat a bacterial infection).
              </li>
              <li>
                  Treating or relieving the symptoms of a chronic or non-chronic illness (e.g., asthma medications, painkillers, etc.)
              </li>
              <li>
                  Prevention of a disease (e.g., the flu vaccine).
              </li>
              <li>
                  Reducing the progression of a disease (e.g., drugs for dementia).
              </li>
              <li>
                  The diagnosis of a pathological condition (e.g., contrast tomography).
              </li>
              </ul><p>`,
        },
        {
          question: 'Prescription and non-prescription medicines',
          answer: `<p><b>Prescription medicines (SY.FA.)</b> are those that in order to buy you need to have a prescription from a doctor [1]</small>.</p>
              <b>Non-prescription drugs (NSAIDs)</b> are those that you can buy without the need for a doctor's prescription, are usually taken for a short period of time, and are mainly for mild conditions that you can deal with on your own (e.g., x. headache pills).
              <br><br> <p><small>[1] For the possibility of a free prescription, you can contact your Municipality to inform you if it is done in the Municipal Clinics and if it concerns only specific groups of the population or all citizens.</small></p>`,
        },
        {
          question: 'Original and generic medicines',
          answer: `<p><b>An original medicine</b> is one that is produced and sold by the pharmaceutical company that created it from the beginning. When a pharmaceutical company discovers a new medicine, it has for some years the exclusive rights to produce and sell it ("patent"), ie no other pharmaceutical company can manufacture and sell a corresponding medicine with the same active ingredient (the component of the medicine due to its action in the body (see "What is the active substance"). After some years (20-25) the patent expires, thus allowing other pharmaceutical companies to develop and sell medicines containing the same active substance as the original medicinal product and which are called generics.</p>
              <b>Generic medicines</b> meet the same strict quality, safety, and efficiency standards as the originals. In this case, many medicines with different brand names may be released, but the active substance they contain is the same (even the form and dosage). Generic medicines may differ from the original medicines in form, size, shape, color, packaging, and inactive components (called excipients), ie those components that do not contribute to the action of the medicine. However, they are used to treat the same conditions as the originals and are just as effective. Generic medicines cost less than the original ones because, obviously, the pharmaceutical companies that sell them have not spent money on their research and development. The decision about which medicine to take and whether it is original or generic should always be made under the guidance of your doctor or pharmacist.`,
          },
        {
          question: 'What is the active substance of a medicine?',
          answer: 'The active substance of medicine is the ingredient that allows it to have a therapeutic effect on the human body, that is, makes it effective in the treatment of the disease or condition for which we take it, or to have a medicinal effect, such as the medicines we take. to do a diagnostic test (eg the contrast medicine given before the CT scan). The dosage recommended by your doctor or pharmacist for each medicine is essentially the amount of active substance you need to take to be able to have the desired effect on improving your health. The warnings that exist, also, for possible side effects in all medicines, are those that can in some cases be caused by the active substance of each medicine. ',
        },
        {
          question: 'What are antibiotics and what is their correct intake?',
          answer: `<p><b>What are antibiotics?</b></p><p>Germs include bacteria, viruses, fungi, and parasites. Antibiotics have been developed to treat infections caused exclusively by bacteria by killing them or stopping them from growing. Antibiotics do not work against all bacteria, which means that not all of them are suitable for treating any type of bacterial infection. Some aim to treat a single class of bacteria, while others aim to treat more than one. However, antibiotics are by no means effective against infections caused by other types of germs, such as viruses (such as the flu or the common cold).</p>
          <p><b>What is antibiotic resistance (or antimicrobial resistance)?</b></p><p>Antibiotic resistance is not related to the human body, that is, our body does not become resistant to antibiotics. It means that the bacteria develop the ability to overcome the medicines that are designed to treat them, so they are not killed and continue to grow despite taking them. Consequently, the bacterial infection in our body is not cured. These bacteria were previously sensitive to the antibiotics that were created to fight them, but they eventually managed to genetically alter (mutate) and develop resistance to them.</p>
          <p><b>Why does antibiotic resistance occur?</b></p><p>Developing resistance of bacteria to certain antibiotics is a natural development that can occur. However, when antibiotics are taken unnecessarily, excessively, and inappropriately, this process becomes much faster and to a greater extent. That is, bacteria are created for which there are no antibiotics available that can treat them. This means that while in the past some infections were treated with certain antibiotics, now they are becoming dangerous to humans again, as the antibiotics that were available to treat them are no longer effective.</p>
          <b>What to do & not to do about antibiotics</b></p><p>The responsible and correct intake of antibiotics helps to reduce the phenomenon of resistance so that these medicines remain effective in the treatment of bacterial infections. That is why it is very important to follow the following basic instructions:</p>
          <p><ul>
              <li>
                  <b>Only take antibiotics when recommended by your doctor. Antibiotics can only be obtained with a prescription.</b>
              </li>
              <li>
                  Follow your doctor's instructions exactly for the type of antibiotic you will be taking, the dosage, and the duration you will be taking it.
              </li>
              <li>
                  Common infections caused by viruses (not bacteria), such as whooping cough, cough, sore throat, bronchitis, otitis, sinusitis, etc. They are NOT treated with antibiotics.
              </li>
              <li>
                  Never use antibiotics that you may have left at home from previous treatment or on the advice of relatives and friends. Only a doctor can decide if you should take an antibiotic. Taking antibiotics when you should not do more harm to your health than good.
              </li>
          </ul>,<p>`,
        },
        {
          question: 'What are medication compliance, drug overdose, sub-therapeutic dose, and multi pharmacies?',
          answer: `<p><b>Compliance with the medication:</b> To faithfully follow the medication prescribed by the doctor and the relevant instructions for taking it, even without the close supervision of the doctor.</p>
              <p><b>Medicine overdose or abuse:</b> Take a higher dose of medicine than your doctor prescribes and it is recommended that you take it. This can be done by mistake, for example in case someone forgets that he took the dose of the medicine and takes a second one.</p>
              <p><b>Hyper therapeutic dose:</b> Take less medicine than your doctor prescribes and it is recommended to take or skip doses.</p>
              <p><b>Multitherapy:</b> Taking multiple medications at the same time, prescription or not. It can lead to mistakes in taking and dosing and to harmful interactions between them.</p>`,
        },
        {
          question: 'What are the side effects or bad side effects of medicine?',
          answer: `<p>They are unwanted or unexpected symptoms that can be observed after using a medicine. These side effects can be mild or even severe to the point that they can be life-threatening (eg dizziness, nausea, confusion, headache, dry mouth, shortness of breath, rash, allergic reaction, damage to organs of the body, etc.). These include those known to be caused by the use of a medicament e.g., according to the approved indications (and therefore mentioned in the package leaflet of the medicine), and those caused by other events, such as overdose, misuse, abuse, etc. If you suspect that a medicine may have caused you some side effects contact your doctor or pharmacist directly for information.</p>
          <p><b>CAUTION: Do not stop your medication unless your doctor tells you to.</b></p>
          <p>It does not necessarily mean that the medicine or the active substance of the medicine causes the side effects or that the medicine cannot be used safely. Whether or not you will continue your medication and how this will be done will only be decided by your doctor. The National Medicines Agency (EOF) has created the "Yellow Card" as a means of gathering information on the side effects of drugs. The "Yellow Card" is accessible via the electronic link https://www.eof.gr/web/guest/yellowgeneral. If you want to alternatively fill in the "Yellow Card" in printed form, you can contact the Department of Side Effects of EOF (284 Mesogeion, 155 62, Athens or the contact numbers 213 2040380 and 213 2040337, Fax 210 6549585) or directly to your pharmacy. The "Yellow Card" can be completed by both health professionals and patients themselves. So do not hesitate to mention side effects that are unknown to you and are not listed in the package leaflet of this medicine or if you have any doubts about whether they are related to any medicine you are taking. The information you provide can be very important to make known a side effect of medicine so that steps can be taken to ensure that patients take it more safely and safely. If you have difficulty filling out the "Yellow Card" yourself, ask your doctor or pharmacist for guidance.</p>`,
        },
        {
          question: 'How can medicine provoke interactions?',
          answer: `<p><b>With other medicines</b></p><p>It occurs when two or more medicines react with each other and cause unexpected actions. If you are taking different medicines and / or you are being watched by more than one doctor, you should know both you and all of your medication that you are taking to avoid any interactions between medicines.</p>
          <p><b>With food / beverages</b></p><p>Food can affect how the body absorbs a medicine. Also, concomitant intake of alcohol and certain medicines can reduce their effectiveness and / or cause side effects such as fatigue and reduction in the speed of reflexes. So it is important to follow closely the instructions for taking a medicine you will receive from your doctor or pharmacist.</p>
          <p><b>With existing diseases</b><p><p>The existence of a disease may not allow certain medicinal products to be taken, as it can make it dangerous for your health. This means that your doctor is important to know, except all your medication, and your whole medical history.</p>`,
        },
        {
          question: 'How can I get my medicine right?',
          answer: `<p>Some medications will need to be taken for a period of time (until you are actually cured of the condition you are taking them for), while in other cases you may need to take them for the rest of your life (as is often the case with chronic illnesses, e.g..x. diabetes). Therefore, proper medication intake is very important for your health. If you do not follow your doctor or pharmacist's instructions on how to take a medicine, it may reduce its effectiveness and ultimately not help you or end up hurting your health.</p>
          <p>That is why it is very important to:</p>
          <p><ul>
            <li>
              take exactly the dose recommended by your doctor or pharmacist.
            </li>
            <li>
              take the medicine at the hours and days recommended by your doctor or pharmacist.
            </li>
            <li>
              take the treatment for the period of time recommended by your doctor or pharmacist (no more, no less)
            </li>
            <li>
              follow the instructions for taking the medicine given to you by your doctor or pharmacist as to whether or not you should take this medicine with or without food and if you should avoid taking certain foods or drinks for as long as you take it.
            </li>
            <li>
              do not take expired medicines.
            </li>
            <li>
              keep in mind that some medicines, if you open the package (eg eye drops), then expire earlier than the expiry date stated on the package (in which case the expiry date after opening is indicated on the package leaflet medicine).
            </li>
            <li>
              talk to your doctor or pharmacist for guidance on what to do if you miss a dose, do not decide for yourself.
            </li>
            <li>
              do not crush tablets to make powder or open capsules to make them easier to swallow unless your doctor or pharmacist tells you that this is safe for this medicine.
            </li>
            <li>
              do not ignore an unexpected side effect that you think may be related to a medicine you are taking, contact your doctor or pharmacist directly.
            </li>
          </ul></p>
          <p>It is also important not to give friends and relatives medicines prescribed by your doctor for you, as you may not know exactly what they are suffering from and whether the medicine you are taking will help or harm them. Only the doctor who examines them can decide which medicine they should take, what is the right dose, and for how long they should take it. Similarly, do not use medicines that have been prescribed for your relatives or friends. For more information see the section "What can you do with expired medications but no longer need them".</p>`,
        },
        {
          question: 'How to organize the medication I am taking?',
          answer: `
          <ul>
            <li>
              Use a medication adjustment box for the whole week, which will help you organize the medications you take on a daily basis. This box can be obtained from any pharmacy.
            </li>
            <li>
              If the time your doctor or pharmacist tells you to take a medicine coincides with another time, combine the two actions (eg take the medicine as soon as you brush your teeth every morning).
            </li>
            <li>
              Stick notes with reminders to take the medicine in places you see all the time, such as the bathroom mirror or the refrigerator door.
            </li>
            <li>
              Use a diary to keep track of each time you take your medicine and the dose you take.
            </li>
            <li>
              Make reminders on your cell phone to let you know the days and times you need to take your medicine.
            </li>
            <li>
              If possible, ask a family member or friend to help you, reminding you when you need to take your medicine.
            </li>
            <li>
              Talk to your doctor or pharmacist for guidance on what to do if you miss a dose, do not decide for yourself.
            </li>
            <li>
              Do not crush tablets to make powder or open capsules to make them easier to swallow unless your doctor or pharmacist tells you that this is safe for this medicine.
            </li>
            <li>
              Do not ignore an unexpected side effect that you think may be related to a medicine you are taking, contact your doctor or pharmacist directly.
            </li>
          </ul>
          `,
        },
        {
          question: 'What do I do before I start taking medicine?',
          answer: `<p>Inform your doctor or pharmacist:</p>
          <p><ul>
            <li>
              For any other medicines, you are taking, even if they are herbal, vitamins, or dietary supplements. Ask if you can take them with your new medicine or if you need a change. You will thus be able to avoid any interactions between the various medications that may be detrimental to your health.
            </li>
            <li>
              If you have a proven allergy to certain medications.
            </li>
            <li>
              If you have a surgery scheduled.
            </li>
            <li>
              If you are pregnant or breastfeeding.
            </li>
            <li>
            If there is something you do not understand about the purpose, method of administration, dosage, or any other relevant information about the medicine.
            </li>
          </ul></p>
          <p>To have a good picture of all the medicines you use, but also to be able to inform your doctor or pharmacist at any time, it is very useful to make a list of all the medicines you are taking. A table, ie for each medicine that could have columns:</p>
          <ol>
            <li>
              Drug Name
            </li>
            <li>
              Why am I taking the medicine?
            </li>
            <li>
              Form (eg pill, drops, syrup, patch, injection, etc.).
            </li>
            <li>
              Dose (eg 1mg, 20ml, etc.).
            </li>
            <li>
              How often should I take it & how many doses.
            </li>
            <li>
              Date I started taking it.
            </li>
            <li>
              Date I should stop taking it (if I have a doctor or pharmacist).
            </li>
            <li>
              Other instructions on how to take it (eg with food or fasting / or should not drink alcohol, etc.).
            </li>
          </ol>`,
        },
        {
          question: 'What should I ask my doctor or pharmacist before I start taking medicine?',
          answer:
          `<ul>
              <li>
                  What does this medicine do?
              </li>
              <li>
                  What will it help me with?
              </li>
              <li>
                  Why should I take it?
              </li>
              <li>
                  Does it replace any of the medications I am already taking?
              </li>
              <li>
                  When and how should I take it? How much will I get each time?
              </li>
              <li>
                  How long will it take me to get it?
              </li>
              <li>
                  Can I chew, crush or melt this medicine to get it?
              </li>
              <li>
                  When will I start to see some improvement? How do I know I'm getting better?
              </li>
              <li>
                  Do I need to do any tests to see if the medicine really helps me? If so, what are these tests and when should I take them?
              </li>
              <li>
                  What should I do if I miss a dose?
              </li>
              <li>
                  What should I do if I accidentally take more medicine than I should?
              </li>
              <li>
                  Are there any possible side effects of taking this medicine?
              </li>
              <li>
                  Do I need to avoid certain medications or supplements for as long as I take this medication?
              </li>
              <li>
                  Do I need to avoid certain foods, drinks, or activities (eg driving) for as long as I take this medicine?
              </li>
              <li>
                  What is the best way to store this medicine?
              </li>
              <li>
                  Is there a case for me to develop tolerance or dependence on this particular drug?
              </li>
              <li>
                  Does my insurance cover this medicine?
              </li>
          </ul>`,
        },
        {
          question: 'How do I store my medicines?',
          answer: `<p>Each medicine is different, so always ask your doctor or pharmacist how to store it and read the package leaflet (FOX) carefully.</p>
          <ul>
            <li>
              As a general guideline, store medicines in a cool, dry place out of direct sunlight, as they can be harmful. That's why it's not good to keep medicines in the bathroom, kitchen, or car, where it is hot and humid.
            </li>
            <li>
              Some medicines may need to be stored in the refrigerator. Especially insulin, vaccines, and some eye drops and syrups require specific temperature conditions not only when stored but also when transported from the pharmacy to your home. Therefore, for these classes of medicines, follow faithfully and with special care the instructions that the pharmacist will give you both for their transport and their storage.
            </li>
            <li>
              Always keep the medicines in their original packaging, because it has the expiration date of the medicine on it and the package leaflet inside.
            </li>
            <li>
              Do not put different medicines in the same container.
            </li>
            <li>
            <p>Do not use pills or tablets if they are damaged, ie:</p>
              <ol>
                <li>
                  are fragile or have disintegrated.
                </li>
                <li>
                  have discolored.
                </li>
                <li>
                  are soft (while not normally so).
                </li>
                <li>
                  creams that have changed their texture, color, and/or smell bad.
                </li>
                <li>
                  Keep medicines in a safe place out of the reach and sight of children (see Parenting Tips).
                </li>
            </li>

          `,
        },
        {
          question: 'What should parents be aware of about their medications and children?',
          answer: `<p><b>Put the medicines and vitamins you have at home in a safe place that children do not see and cannot reach.</b></p><p>Find such a place in your home and store there all the medicines and vitamins you have, even the ones you use daily. Do not scatter them in different parts of the house and do not leave them in common view and in places that could be reached by small children. The medicines we leave on benches, tables, nightstands, bags, or wallets can be easily accessed by young children, who have an innate curiosity about everything around them and put all sorts of things in their mouths.</p>
          <p><b>If there is a safety cap on the package, make sure you always close it properly after each use.</b></p><p>If there is a safety cap on the medicine package, turn it over until you hear a "click" feature. Even so, this does not mean that children could not open the package. So again even these medicines should be kept together with all the rest away from children, as mentioned above, and never given to them to keep as a toy even if it is for a while.</p>
          <p><b>Pay special attention to the dosages of the medicines you give to the children.</b></p><p>You are well aware of the dosage of the medicine you are going to give your child. Follow the instructions of your pediatrician or pharmacist carefully and read the package leaflet carefully. If the medicine is in liquid form, there is usually a dose-measuring instrument (teaspoon, cup, or syringe) already in the package. If not, ask your pharmacist to give you one that is right for you. Do not use spoons, as they come in different sizes and are not suitable for this purpose, so you may end up giving your child a smaller or larger dose of medicine than they should. If the child is unable to take the full dose at some point (because he is crying, spitting on some of it, vomiting after a while, etc.), do not give him a second dose directly even if you have doubts about whether managed to get the dose he needed. Talk to your pediatrician to guide you on what is best to do in each case.</p>
          <p><b>Discuss with your children about medicines and how they are used safely</b></p><p>Discuss with your children and explain calmly and logically what the medicine is, why we take it and why they should only take it when given to them by an adult in the family. Do not tell your children that medicines are candies to persuade them to take them. Many drugs are brightly colored and may look like candy to their eyes, making the test even more tempting. Teach older siblings that they should never give medication to younger siblings before first talking to an adult in the family.</p>
          <p><b>Ask guests and guests to follow the same rules.</b></p><p>Remind guests and guests who have medicines with them that they should also keep them, while they are at home, in a safe place that small children do not see or reach.<p>
          <p><b>Be prepared in case of an emergency.</b></p><p>Always have the emergency number (166) and the Poison Center (2107793777) available and call immediately if you think your child may have taken any medicine or vitamins on their own.</p>`,
        },
        {
          question: 'What should people taking care of other people`s medicines watch out for?',
          answer: `<p><b>Get to know all the medication he is taking and plan how he will take it.</b></p><p>Make a list of all prescription and over-the-counter medications, including herbal remedies and supplements. If for some reason you do not take a dose of the drug, do not give a double later or try to make up for it in some way. Talk to your doctor or pharmacist for advice on what to do in each case depending on your medication. Use a medication adjustment box for the whole week, which will help you organize daily the medications that the person you care for needs to take. You can also use a diary to more easily remember when to take each medication and dosages, as well as important appointments with the attending physicians. Medications should also be given at certain times of the day and in some cases should be combined with food, while others should not. If you do not follow the exact instructions of your doctor or pharmacist to take the medicine correctly, its therapeutic effects may be reduced.</p>
          <p><b>Seek regular communication and cooperation with your doctor and pharmacist.</b></p><p>For any new medicine, you need to take, first ask your doctor or pharmacist if it is safe in combination with all the other medicines he is taking. Important questions to ask your doctor or pharmacist about any new medicine you are about to take are:</p>
          <p><ul>
            <li>
              What is the purpose of this medicine?
            </li>
            <li>
              What improvement in the health of the person I take care to expect and in how long will it happen?
            </li>
            <li>
              When and how should he take this medicine, what is the dose, and for how long will he take it?
            </li>
            <li>
              What do I do if I miss one or more doses?
            </li>
            <li>
              Can he take this medicine in combination with the rest he is taking?
            </li>
            <li>
              Are there any possible side effects that may occur and what are they?
            </li>
            Make sure you keep in touch with the doctors who are watching him/her and arrange the appointments he/she needs with them so that they can review the medication at regular intervals and adjust it if necessary. For long-term medication, be sure to renew your prescriptions in time before your home medication is completely exhausted so that you do not miss any of the doses. At your doctor's appointment, discuss whether the person you are caring for is improving and if he or she has had side effects or new symptoms that he or she has not had before.
          </ul></p>
          <p><b>ΤWhat to do if he refuses to take the medicine.</b><p><p>If the person you take care, refuses to take his medication, try to understand exactly where the problem lies. In other words, this medicine may cause some side effects that it wants to avoid or it may be difficult for you to swallow a large pill. Do not try to give him the medicine by force. Avoid crushing tablets to turn them into powder or opening capsules to mix them with a drink or food, as this may not be safe. Consult your doctor or pharmacist about what to do in these cases. For example, they may suggest another drug that is just as effective but is in liquid form, so that it is easier to swallow.</p>`,
        },
        {
          question: 'Where do I throw my medicines when they expire?',
          answer: `<p>Expired medicines should NOT be discarded:</p>
          <p><ul>
            <li>
              in the neighborhood rubbish bins
            </li>
            <li>
              in the sink
            </li>
            <li>
              in the toilet bowl,
            </li>
          </ul></p>
          <p>because the chemicals they contain pollute both the soil and the water in the environment, posing a significant risk to everyone's health. Expired medicines should only be thrown away in the green metal bins found in pharmacies. There they are temporarily collected and handed over to the Institute of Pharmaceutical Research and Technology (IFET) to be safely destroyed.</p>
          <p><b>What can you throw in the green bins of pharmacies?</b></p>
          <p><ul>
            <li>
              Pills and capsules in the foil containing them (paper and plastic packaging of medicines should be disposed of in recycling bins).
            </li>
            <li>
              Well-sealed glass bottles of syrups and vials for injection.
            </li>
            <li>
              Tubes with ointments and creams.
            </li>
          </ul></p>
          <p><b>What you should NOT throw in the green bins of pharmacies.</b></p>
          <ul>
            <li>
              Mercury (eg thermometers)
            </li>
            <li>
              Syringes
            </li>
            <li>
              Cracked injection vials
            </li>
            <li>
              Broken syrup bottles
            </li>
            <li>
              Gauze
            </li>
            <li>
              Cotton
            </li>
          </ul>`,
        },
        {
          question: 'What should I do with my surplus medications?',
          answer: `<p>If you have medicine that is about to expire or you have leftovers because your treatment is over, you can donate them to other people who need them, even if you have a small amount left.</p>
          <p>Το <b>GIVMED</b> is a non-profit organization that aims to give people immediate access to the medicines they need. You can easily and quickly donate the medicines you no longer need to social pharmacies, nursing homes, and other charities.</p>
          <p>At the <b>GIVMED</b> website <a href="https://givmed.org/" target="_blank">(https://givmed.org/)</a> you can find the charities that are near you and need the medicines you wish to donate. If you are unfamiliar with the technology, you can call GIVMED (210 3007222) for directions.</p>
          It is important that the medicine or medicines you wish to donate meet the following conditions:
          <p><ul>
            <li>
              The expiration date has not passed.
            </li>
            <li>
            It is not a medicine that needs a refrigerator.
            </li>
            <li>
            It is not open if it is a syrup, ointment, drops, or inhaled product.
            </li>
            <li>
              It does not belong to the category of digram drugs.
            </li>
            <li>
              It has been stored at a controlled room temperature of 25 degrees and away from any kind of heat or humidity source.
            </li>
            <li>
              The medicine voucher (i.e. the barcode sticker) has been removed.
            </li>
          </ul></p>
          <p><b>Do not forget that medicine is a good to which all people have the right to be able to take care of their health in the best possible way. If you have any further questions or concerns, do not hesitate to talk to your doctor and pharmacist, who are best placed to assist you and guide you through what you need to know about your medication and take care of your health.</b></p>`,
        },
      ],
  },
  logout: {
    title: 'خروج از حساب',
  },
  organizationIsClosed: 'Closed',
  weekdays: {
    monday: 'دوشنبه',
    tuesday: 'سه شنبه',
    wednesday: 'چهارشنبه',
    thursday: 'پنجشنبه',
    friday: 'جمعه',
    saturday: 'شنبه',
    sunday: 'یکشنبه',
  },
  quantityType: {
      BOX: 'قوطی ها | قوطی ها',
      BAG: 'کیسه ها | کیسه ها',
      PIECE: 'قطعه ( واحد قوطی) | قطعه ( واحد قوطی)',
      OTHER: 'غیره | غیره',
      ML: 'میلی گرم',
      pieces: 'قطعه ها | قطعه ها',
      subUnit: '(واحد قوطی) | (واحد قوطی)',
  },
  quantityTypePlural: {
      BOX: 'قوطی ها',
      PIECE: 'قطعه ( واحد قوطی)',
      OTHER: 'غیره',
  },
  labels: {
    lang: 'زبان',
    address: 'آدرس',
    phone: 'شماره تلفن',
    medName: 'نام دارو',
    medSubs: 'ماده فعال',
    hygProdName: 'نام محصول بهداشتی',
    hygProdCategoryOrSubCategory: 'زیر شاخه | نام دسته بندی ',
    subCategory: 'زیر شاخه',
    expDate: 'تاریخ انقضا',
    quantity: 'مقدار',
    productUnit: 'واحد محصول',
    needDate: 'تاریخ ثبت',
    concentration: 'غلظت',
    boxes: 'قوطی دارو | قوطی دارو',
    newSearch: 'جستجوی جدید',
    newNeed: 'افزودن نیاز دارویی جدید',
    checkMore: 'بیشتر ببین',
  },
  organizationDocuments: {
    ekkatharistiko: 'صفر کردن حساب مالیاتی',
    apodeiktikoKatoikias: 'تاییدیه رسمی آدرس محل سکونت (قبض آب یا برق، قرارداد اجاره منزل)',
    sintagi: 'نسخه پزشک',
    pistopoihtikoOikogenneiakisKatastasis: 'گواهی وضعیت خانوادگی',
    pistopoihtikoEualototitas: 'هرگونه گواهی آسیب پذیری (مثال: درصد معلولیت، داشتن فرزند زیاد و غیره)',
    tautotita: 'فوتوکپی پاسپورت و یا کارت شناسایی',
    forologikiDilosiE1: 'برگه اظهارنامه شخصی حساب مالیاتی (Ε1)',
    kartaAnergias: 'برگه یا کارت گواهی عدم اشتغال',
    dilosiAkinitisPeriousiasE9: 'گواهی ثبت دارایی (Ε9)',
    asfalisi: 'گواهی عدم داشتن بیمه سلامت. برای اشخاصی که دارای بیمه سلامت هستند به همراه داشتن صفحه اول دفترچه بیمه ضروری می باشد.',
    kEA: 'در صورتی که بیمار کمک هزینه تأمین اجتماعی (KEA) دریافت می کند،  فقط به همراه داشتن مدرک مربوطه و مدرک شناسایی کافی است.',
    aitisi: 'فرم درخواست (این فرم در محل مربوطه به متقاضی داده می شود.)',
    adeiaParamonis: 'مدرک اجازه اقامت دارای اعتبار در زمان درخواست (برای اتباع خارجی)',
    vevaiosiDimou: 'در صورت تغییر منطقه محل سکونت، گواهی عدم دریافت خدمات مشابه از منطقه های دیگر. ',
    sintaxiMisthodosia: 'گواهی حقوق ماهیانه دریافت شده و یا حقوق بازنشستگی دریافت شده',
    fotografia: 'یک قطعه عکس (نوع گذرنامه)',
    pistopoihtikoGennisis: 'گواهی تولد (برای افراد زیر سن قانونی)',
    aFM: 'شماره مالیاتی (آ.فی.می)',
  },
  newAppVersionAvailable: {
    text: 'نسخه جدید آماده است! جهت به روز سازی کلیک کنید.',
    btn: 'به روز سازی',
  },
  offlineMode: {
    text: 'شما در حال حاظر آفلاین هستید',
  },
  iosInstallBanner: {
    text: 'جهت نصب اپلیکیشن مِد فور یو (MEDforU) دکمۀ share را کلیک کنید و سپس دکمۀ Add to homescreen را کلیک نمایید.',
  },
  errorCodes: {
    serverConnectionProblem: 'ما با یک مشکل تکنیکی مواجه شده ایم. لطفاً با ما تماس بگیرید.',
    generalValidationError: 'مشخصات ورود را اشتباه وارد کردید.',
    multipleObjectsReturned: 'موارد متعدد بازگشت خورد',
    accountDisabled: 'حساب کاربر غیر فعال شد.',
    userAlreadyExists: 'این آدرس ایمیل در حال حاضر توسط کاربری دیگر استفاده می شود.',
    wrongCredentials: 'آدرس ایمیل و یا رمز عبور وارد شده اشتباه است',
    validationErrorException: 'ما با یک مشکل تکنیکی مواجه شده ایم. لطفاً با ما تماس بگیرید',
    invalidToken: 'ما با یک مشکل تکنیکی مواجه شده ایم. لطفاً با ما تماس بگیرید.',
    wrongCurrentPassword: 'رمز عبور فعلیِ وارد شده اشتباه است.',
    authenticationFailed: 'شناساییٍ کاربر، ناموفق بود.',
    coveredQuantity: 'مقدار داروی دریافت شده بیشتر از مقدار داروی درخواست شده بود.',
    sendEmailException: 'هنگام فرستادن ایمیل به کاربر، مشکلی پیش آمد.',
    transactionIntegrityError: 'ما با یک مشکل تکنیکی مواجه شده ایم.',
    productNotFound: 'This product is not founded.',
    productNotMatchedWithYourNeeds: 'This product is not matched with your needs.',
    requestedReservedQuantityGreaterThanPermitedAvailableQuantity: 'Requested reserved quantity of this product is greater than permitted available quantity.',
    noEmailSetForThisOrganization: 'There is not email set for this organization.',
  },
};
