export default {
    path: '/',
    name: 'main',
    meta: {
        requiresAuth: false,
    },
    needs: {
        path: 'needs',
        name: 'needs',
        add: {
            path: 'add-needs',
            name: 'add',
            meta: {
                requiresAuth: false,
                canGoToPreviousPage: false,
                pageTitle: 'addNeeds.title',
                menu: {
                    isNeedGroup: true,
                    icon: 'mdi-plus-box-outline',
                    label: 'addNeeds.title',
                    order: 1,
                },
            },
            medNeeds: {
                path: 'add-med-needs',
                name: 'medNeeds',
                meta: {
                    requiresAuth: false,
                    pageTitle: 'addNeeds.title',
                },
            },
            medSubstanceNeeds: {
                path: 'add-med-substance-needs',
                name: 'medSubstanceNeeds',
                meta: {
                    requiresAuth: false,
                    pageTitle: 'addNeeds.title',
                },
            },
            hygProdNeeds: {
                path: 'add-hyg-prods-needs',
                name: 'hygProdNeeds',
                meta: {
                    requiresAuth: false,
                    pageTitle: 'addNeeds.title',
                },
            },
            hygProdCategoryNeeds: {
                path: 'add-hyg-prods-category-needs',
                name: 'hygProdCategoryNeeds',
                meta: {
                    requiresAuth: false,
                    pageTitle: 'addNeeds.title',
                },
            },
        },
        basket: {
            path: 'basket',
            name: 'basket',
            meta: {
                requiresAuth: false,
                pageTitle: 'needsBasket.title',
            },
        },
        panel: {
            path: 'requested',
            name: 'needsPanel',
            meta: {
                requiresAuth: true,
                pageTitle: 'needsPanel.title',
                menu: {
                    isNeedGroup: true,
                    icon: 'mdi-clock-outline',
                    label: 'needsPanel.title',
                    order: 2,
                },
            },
        },
        AutoMatchWithOrganizationProducts: {
            path: 'automatch-with-organization-products',
            name: 'AutoMatchWithOrganizationProducts',
            meta: {
                requiresAuth: true,
                pageTitle: 'automatch.title',
                menu: {
                    isNeedGroup: true,
                    icon: 'mdi-send-outline',
                    label: 'automatch.title',
                    order: 3,
                },
            },
        },
        satisfied: {
            path: 'satisfied',
            name: 'satisfied',
            meta: {
                requiresAuth: true,
                pageTitle: 'satisfiedNeeds.title',
                menu: {
                    isNeedGroup: true,
                    icon: 'mdi-folder-download-outline',
                    label: 'satisfiedNeeds.title',
                    order: 4,
                },
            },
        },
    },
    map: {
        path: 'https://givmed.org/en/dorea-farmakon/',
        name: 'map',
        meta: {
            requiresAuth: false,
            isGivmedHomepageUrl: true,
            menu: {
                icon: 'mdi-map-marker-outline',
                label: 'map.title',
                order: 5,
            },
        },
    },
    profile: {
        path: 'profile',
        name: 'profile',
        meta: {
            requiresAuth: true,
            pageTitle: 'profile.title',
            menu: {
                label: 'profile.title',
                icon: 'mdi-account-outline',
                order: 6,
            },
        },
    },
    about: {
        path: 'about',
        name: 'about',
        meta: {
            requiresAuth: false,
            pageTitle: 'about.title',
            menu: {
                label: 'about.title',
                icon: 'mdi-information-outline',
                order: 7,
            },
        },
    },
    tutorial: {
        path: 'tutorial',
        name: 'tutorial',
        meta: {
            requiresAuth: false,
            pageTitle: 'tutorial.title',
            menu: {
                label: 'tutorial.title',
                icon: 'mdi-book-open-page-variant-outline',
                order: 8,
            },
        },
    },
    faq: {
        path: 'faq',
        name: 'faq',
        meta: {
            requiresAuth: false,
            pageTitle: 'faq.title',
            menu: {
                label: 'faq.title',
                icon: 'mdi-help-circle-outline',
                order: 9,
            },
        },
    },
    terms: {
        path: 'terms-data-protection',
        name: 'terms',
        meta: {
            requiresAuth: false,
            pageTitle: 'terms.title',
            menu: {
                label: 'terms.title',
                icon: 'mdi-text-search-variant',
                order: 10,
            },
        },
    },
    contact: {
        path: 'contact',
        name: 'contact',
        meta: {
            requiresAuth: false,
            pageTitle: 'contact.title',
            menu: {
                label: 'contact.title',
                icon: 'mdi-message-text-outline',
                order: 11,
            },
        },
    },
};
