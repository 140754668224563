<template>
    <v-snackbar
        v-if="notification.show"
        v-model="notification.show"
        multi-line
        top
        :timeout="getms"
        :color="notification.status === 'ERROR' ? 'red accent-4' : 'success'"
        elevation="8"
        data-cy="base-notification"
        @input="dismissGenericNotification"
    >
        {{ notification.text }}
        <template #action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="dismissGenericNotification">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { defineComponent } from 'vue';
import useSettings from '../../modules/settings.module';

export default defineComponent({
    setup() {
        const { notification, dismissGenericNotification } = useSettings();

        return {
            notification, dismissGenericNotification,
        };
    },
    computed: {
        getms() {
            if (this.notification.status === 'ERROR') {
                return this.notification.ms ?? 6000;
            } else {
                return this.notification.ms ?? 3000;
            }
        },
    },
});
</script>

<style lang="scss" scoped></style>
