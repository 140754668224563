export const DEBOUNCE_TIME = 700;
export const REGION_DEBOUNCE_TIME = 900;
export const MAX_HEIGHT_AUTOCOMPLETE = 300;

/** @type {MedForU.LangIsoEnum} */
export const LangIsoEnum = {
    Ar: 'ar',
    El: 'el',
    En: 'en',
    Fa: 'fa',
    Fr: 'fr',
    Uk: 'uk',
};

/** @type {MedForU.LangNameEnum} */
export const LangNameEnum = {
    Ar: 'عربى',
    El: 'Ελληνικά',
    En: 'English',
    Fa: 'فارسی',
    Fr: 'Français',
    Uk: 'Українська',
};

/** @type {MedForU.SexEnum} */
export const SexEnum = {
    Female: 'F',
    Male: 'M',
    NonBinary: 'NB',
    NoneOfTheAbove: 'NOTA',
    NoAnswer: null,
};

/** @type {MedForU.NotificationStatusEnum} */
export const NotificationStatus = {
    Error: 'ERROR',
    Success: 'SUCCESS',
};

/** @type {MedForU.NeedStatusEnum} */
export const NeedStatus = {
    Pending: 'PE',
    Satisfied: 'SA',
};

/** @type {MedForU.ConcentrationUnitEnum} */
export const CONCENTRATION_UNIT = Object.freeze({
    ML: 'ML',
    MG: 'MG',
    G: 'G',
    MCG: 'MCG',
    PERCENTAGE: '%',
    OTHER: 'OTHER',
});

export const initPaginationData = () => {
    return {
        total_results: 0,
        page: 1,
        // @ts-ignore
        per_page: parseInt(process.env.VUE_APP_PAGINATION_PER_PAGE),
        number_of_pages: 0,
        page_list: [],
    };
};

export const TABS = [
    {
        value: 'MEDS', icon: 'mdi-pill', textToTranslate: 'meds',
    },
    {
        value: 'HYG_PRODS', icon: 'mdi-hospital-box-outline', textToTranslate: 'hygProds',
    },
];

export const ADD_NEED_WAY = Object.freeze({
    ByMedName: 'BY_MED_NAME',
    ByMedSubstance: 'BY_MED_SUBSTANCE',
    ByHygProdName: 'BY_HYG_PROD_NAME',
    ByHygProdCategory: 'BY_HYG_PROD_CATEGORY',
});

export const QUANTITY_TYPES = Object.freeze({
    Box: 'BOX',
    Piece: 'PIECE',
    Other: 'OTHER',
});

export const MODAL_TYPE = Object.freeze({
    Satisfy: 'SATISFY',
    Update: 'UPDATE',
    Delete: 'DELETE',
});

export const QUANTITY_TYPES_DROPDOWN = [
    {
        textToTranslate: 'quantityTypePlural.BOX',
        value: QUANTITY_TYPES.Box,
    },
    {
        textToTranslate: 'quantityTypePlural.PIECE',
        value: QUANTITY_TYPES.Piece,
    },
    {
        textToTranslate: 'quantityTypePlural.OTHER',
        value: QUANTITY_TYPES.Other,
    },
];

export const CONCENTRATION_TYPES_DROPDOWN = [
    {
        text: 'ml',
        value: CONCENTRATION_UNIT.ML,
    },
    {
        text: 'mg',
        value: CONCENTRATION_UNIT.MG,
    },
    {
        text: 'g',
        value: CONCENTRATION_UNIT.G,
    },
    {
        text: 'mcg',
        value: CONCENTRATION_UNIT.MCG,
    },
    {
        text: '%',
        value: CONCENTRATION_UNIT.PERCENTAGE,
    },
    {
        text: 'OTHER',
        value: CONCENTRATION_UNIT.OTHER,
    },
];

/** @type {Array<{textToTranslate: string, value: MedForU.SeX}>} */
export const SEX_TYPES_DROPDOWN = [
    {
        textToTranslate: 'profile.sexTypes.female',
        value: SexEnum.Female,
    },
    {
        textToTranslate: 'profile.sexTypes.male',
        value: SexEnum.Male,
    },
    {
        textToTranslate: 'profile.sexTypes.nonBinary',
        value: SexEnum.NonBinary,
    },
    {
        textToTranslate: 'profile.sexTypes.noneOfTheAbove',
        value: SexEnum.NoneOfTheAbove,
    },
    {
        textToTranslate: 'profile.sexTypes.noAnswer',
        value: SexEnum.NoAnswer,
    },
];
