export default {
    meds: 'أدوية',
    hygProds: 'المنتجات الصحية',
  baseAuth: {
    later: 'لاحقاً',
  },
  preSignup: {
    title: ' لكي تحصل على أدوية ومنتجات صحية، تحتاج لإنشاء حساب مستخدماً عنوان البريد الالكتروني. لن تحتاج للإدلاء بأي بيان آخر',
    agree: ' موافق',
  },
  signup: {
    title: 'تشكيل حساب ',
    repeatPassword: 'اعادة كلمة السر',
    submit: 'تشكيل حساب ',
    agreeToTermsOfUse: 'عند تشكيلك للحساب ان توافق على الشروط والأحكام ',
    termsOfUse: 'شروط الاستخدام ',
  },
  login: {
    title: 'سجل ',
    email: 'بريد الكتروني',
    password: 'كلمة السر ',
  },
  forgotPassword: {
    title: 'نسيت كلمة السر',
    text: 'الرجاء ادخال بريدك الالكتروني للعثور على حسابك , سوف تصلك الإرشادات ك بريد إلكتروني ',
    cancel: 'يلغي',
    submit: 'ارسال',
    successText: ' لقد قمنا بارسال بعض التعليمات لتقوم بإعداد كلمة السر. اذا لم تستلم اي رساله عبر البريد الالكتروني , قم بالتأكد انك ادخلت البريد الإلكتروني نفسه الذي استخدمته لتسجيل حسابك , وتاكد من ملف المهملات أو الاحتيال داخل بريدك الالكتروني ',
  },
  resetPassword: {
    title: 'إعادة تعيين كلمة السر ',
    password: 'كلمة السر',
    repeatPassword: 'كرر كلمة السر',
    submit: 'تاكيد',
  },
  rules: {
    requiredField: 'هذا القسم مطلوب الإجابة عليه ',
    minLength: 'على الاقل ثلاث حروف ',
    passwordLength: 'كلمة السر مؤلفة من 8 حروف و ارقام على الاقل .',
    emailValidation: 'الرجاء ادخال بريد الكتروني صالح .',
    passwordsNotMatch: 'كلمة السر لاتتطابق  .',
    quantity: 'معلومات غير صحيحة',
    invalidBirthYear: 'تاريخ ميلاد خاطئ',
  },
  profile: {
    title: 'ملف المستخدم الشخصي ',
    email: 'بريد الكتروني ',
    language: 'اللغة ',
    appVersion: 'تطبيق النسخة',
    regionalUnities: {
      title: 'مناطق استلام الأدوية والمنتجات الصحية',
      helpText: 'قم باختيار المناطق المهتم بها , ان لم تقم باختيار أي منطقة , الية البحث سوف تعرض لك كل الأماكن المتاحة',
    },
    sex: 'الجنس ',
    sexTypes: {
        male: 'ذكر',
        female: 'أنثى',
        nonBinary: 'لاثنائي',
        noneOfTheAbove: 'لا شيء مما سبق',
        noAnswer: 'أنا أفضل عدم الإجابة',
    },
    birthYear: 'تاريخ الميلاد',
    changesSubmittedSuccessfully: 'تم تطبيق التغييرات بنجاح ',
    notificationsEnabled: 'تم تفعيل الاشعارات.',
    notificationsDisabled: 'تم ايقاف الاشعارات .',
    changePassword: {
      title: 'تغير كلمة السر ',
      currentPassword: 'تنشيط كلمة السر',
      newPassword: 'كلمة سر جديدة ',
      repeatPassword: 'اعادة  كلمة السر ',
      submit: 'تقديم الطلب ',
      succesfulSubmittion: 'تم تغير كلمة السر بنجاح .',
    },
  },
  addNeeds: {
    title: 'تسجيل الاحتياجات الدوائية ',
    byName: {
      title: 'البحث عن دواء',
      Header: ' اكتب اسم الدواء الذي تبحث عنه وقم باختياره من قائمة الادوية ',
      autocompleteLabel: 'اسم الدواء أو اسم المادة الدوائية الفعّالة',
    },
    bySubstance: {
      title: 'البحث عن مادة دوائية فعّالة',
      header: 'اكتب مزيج المواد الدوائية الفعّالة الذي تريده و قم باختياره من اللائحة',
      autocompleteLabel: 'اسم المادة الطبية ',
    },
    save: 'اضف هذا الدواء إلى لائحة طلبات الادوية  ',
    quantityLabel: 'أنت بحاجة كم علبة دواء',
    quantityHygprodLabel: 'كم عدد {quantityType} تحتاج؟',
    concentrationLabel: 'عيار الدواء',
    concentrationPlaceholder: 'بالغرام ٢٥و ٥٠ و ١٠٠ الخ..',
    concentrationUnitLabel: 'الوحدة الدوائية ',
    concentrationUnitPlaceholder: 'ميل , غرام , ميلي غرام الخ….',
    hygProds: {
        byName: {
            title: 'البحث عن أحد المنتجات الصحية',
            header: 'اكتب اسم المنتج الصحي أو الصنف الفرعي الذي تريده وقم بالاختيار من اللائحة ',
            autocompleteLabel: 'اسم المنتج الصحي أو الفئة الفرعية',
        },
        byCategory: {
            title: 'البحث  عن فئة أوصنف فرعي',
            header: 'اكتب اسم الفئة أو الصنف الفرعي الذي تريده وقم بالاختيار من اللائحة.',
            autocompleteLabel: 'الفئة أو الصنف الفرعي للمنتج الصحي',
        },
    },
  },
  needsBasket: {
    title: 'المنتجات المعدّة للطلب',
    completeOrder: 'تم اكمال الطلب ',
  },
  needsPanel: {
    title: 'مالذي قمتُ بطلبه',
    emptyStatesMeds: 'لم يتم تسجيل اي احتياجات دوائية , قم بتسجيل احتياجاتك الدوائية لتحصل على قائمة الموجودات ',
    emptyStatesHygProds: 'لم تقم بتسجل ما تحتاجه من المواد الصحية. سجّل احتياجاتك واطّلع على قائمة ماهو متوفر!',
    actions: {
      moreInfo: ' \'تفاصيل المعلومات ',
      gotMed: 'تم الاستلام',
      edit: 'جاري المعاينة ',
      delete: 'حذف',
      ok: 'اوافق',
    },
    satisfyModal: {
      quantityText: 'الكمية المطلوبة',
      successfulSatisfaction: 'تم تأمين الكمية المطلوبة من احتياجاتك بنجاح',
    },
    updateModal: {
      quantityText: 'كم علبة تحتاج؟',
      successfulUpdate: 'تم تحديث الكمية التي تحتاجها بنجاح',
    },
    deleteModal: {
      areYouSure: 'هل أنت متأكد أنك تريد حذف هذا المنتج بالتحديد من قائمة احتياجاتك ؟',
      successfulDeletion: 'تم حذف هذه الحاجة بنجاح',
    },
  },
  automatch: {
    title: 'ابحث عن دوائك ',
    infoHeaders: [
      'اطّلع على الكمية المتوفرة من الأدوية و المنتجات الصحية التي تحتاجها.',
      'تحقق من أنه لديك الوثائق اللازمة واستلم الأدوية والمنتجات الصحية من المؤسسة الغير بحية التي تختارها.',
    ],
    noAutomatchMeds: ' "الأدوية التي تم تدوينها غير متوفرة، اعتماداً على الاحتياجات الموجودة في صفحة "مالذي قمتُ بطلبه',
    noAutomatchHygProds: '"المنتجات الصحية التي تم تدوينها غير متوفرة، اعتماداً على الاحتياجات الموجودة في صفحة "مالذي قمتُ بطلبه.',
    emptyStatesNoNeeds: 'سجل احتياجاتك من الأدوية والمنتجات الصحية وستجد هنا من أي مؤسسة من المؤسسات الغير ربحية  يمكنك الحصول عليها.',
    emptyStatesNoAutomatch: 'الأدوية والمنتجات الصحية التي تبحث عنها  غير متوفرة في هذا الوقت. حاول مرة أخرى بعد بضعة أيام.',
    emptyStatesNoAutomatchOrNoNeeds: 'ستجد هنا الأدوية والمنتجات الصحية المتوفرة بناءً على احتياجاتك التي قمت بتدوينها. إن كنت قد سجلت',
    map: 'خريطة الموقع ',
    donationConditions: {
      title: 'المتطلبات',
      modal: {
        refugeeNote: ' إن كنت لاجئاً ومسجلاً لدى إحدى المنظمات غير الحكومية، اطلب المساعدة من هذه المنظمة لتتواصل مع المؤسسة غير الربحية',
        title: 'الوثائق المطلوبة من قبل {organization} لكي يمنحك الأدوية والمنتجات الصحية ',
      },
    },
    reserveProduct: {
        button: '   حجز',
        success: ' تم إعلام الصيدلية الاجتماعية {orgName} ( انظر إلى بريدك الالكتروني). يجب أن يتم الاستلام بعد ثلاثة أيام على الأكثر. احرص         على أن يكون معك الوثائق اللازمة ',
    },
  },
  satisfiedNeeds: {
    title: 'ماذا استلمتُ',
    emptyStatesMeds: 'MEDforUلم تطالب بعد باستلام أي أدوية. إذا كنت قد تلقيت أدوية بمساعدة فالرجاء الانتقال إلى صفحة "احتياجات الدواء" والمطالبة بالأدوية التي تلقيتها',
    emptyStatesHygProds: 'لم تصرّح بأنك استلمت أي منتج صحي حتى الآن. اذهب إلى صفحة " ماذا استلمت" وصرّح فيما إذا كنت قد استلمت أي علبة',
  },
  map: {
    title: 'موقع مكان التبرعات',
  },
  faq: {
    title: 'أسئلة متكررة',
    content: [
      {
        question: '  MEDforU  كيف بإمكاني الاتصال بشخص ما للاستفسار عن  ',
        answer: 'أرسل بريدًا إلكترونيًا على hello@givmed.org للاتصال بـ GIVMED ، المنظمة غير الربحية التي أنشأت MEDforU. سيرد أحد أعضاء فريقنا في أقرب وقت ممكن. ساعات الاتصال: من الاثنين إلى الجمعة ، 10:00 - 17:00 ، اللغات المنطوقة: اليونانية والإنجليزية',
      },
      {
        question: 'الى اين يجب ان اذهب للحصول على الدواء ',
        answer: `<p>"نتائج البحث".   على صفحة“MEDforU”  الأدوية تقدمها المؤسسات غير الربحية. ستجدون عنوتنهم من خلال تطبيق </p>
            <p>إن المنظمة غير الربحية  GIVMED, التي أنشأت MEDforU, لاتخزن ولا توزع الأدوية. </p>
        `,
      },
      {
        question: 'هل من المشروط أن اكون مسجل في منظمة ما لأستلم الدواء ;',
        answer: `<p>من الناحية المثالية ، ستتولى منظمة غير حكومية تنسيق تلقي الأدوية الخاصة بك من منظمة غير ربحية حيث وجدتها متوفرة من MEDforU.</p>
            <p> إن لم تكن مسجلاً، تواصل معنا إما لإيجاد إحدى المنظمات أو لنتواصل مع المؤسسة الغير ربحية.</p>
            <p>     في جميع الأحوال يجب أن يكون لديك وصفة طبية من طبيب. </p>
        `,
      },
      {
        question: 'هل أنا بحاجة لوصفة طبية ;',
        answer: `<p>إن الوصفية الطبية لابد منها إن كنت بحاجة إلى أدوية يتم صرفها بوصفة طبية. </p>
            <p>إن كنت بحاجة إلى أدوية يتم لا يوجد شرط صرفها بوصفة طبية، فإن الوصفة ليست ضروية. </p>
        `,
      },
    ],
  },
  tutorial: {
    title: ' دليل الاستخدام',
    mainTitle: 'Do you want to learn step by step how to use our MEDforU app? Watch the video below!',
    iframe: {
        title: 'MEDforU tutorial video',
    },
  },
  terms: {
    title: 'شروط الاستخدام',
    content: [
      {
        header: 'شروط استخدام تطبيق دواء لك',
        text:
          `
      شركة ( دواء لك ) مشاركة الادوية هي مشاركة الحياة , من هذا الحين شركتنا تحاول الاستفادة من الادوية الباقية لصالح المجموعات ذات الأوضاع الخاصة . شركة ( دواء لك ) شكلت هذا التطبيق وتقوم بإدارته لتقوم بفتح الفرصة للأشخاص من المجموعات ذات الأوضاع الخاصة للحصول على الدواء . عند استخدامك لهذا التطبيق أنت توافق على الشروط والأحكام لحماية استخدام التطبيق. حتى تستطيع استخدام التطبيق عليك أولا الموافقة على الشروط والأحكام , الرجاء قراءة الأحكام والشروط بحذر . استخدامك لهذا التطبيق يعني انك قبلت الأحكام والشروط
`,
      },
      {
        header: 'الملكية الفكرية ',
        text:
          `هذا التطبيق بالاضافة لاي معلومات , شعارات او توقيعات من الشركة هو ملكية الشركة ومحمية ضمن القانون الأوروبي. تحت قانون حماية الملكية الفكرية  " (N.2121/1993, 2000/31/ΕΚ and 2001/29/ΕΚ).
          `,
      },
      {
        header: 'حماية معلومات المستخدم ',
        text:
          `
الشركة ملتزمة بنظام حماية عالي لمعلومات المستخدمين الخاصة , على أساس القانون الدولي والقانون الأوروبي لحماية معلومات المستخدمين 4624 /2019  ,676 /2016 الشركة قامت بتفويض المحامية ايليني ديدي 34461   وهي المسؤولة عن حماية معلومات المستخدمين , حيث أنها المسؤولة القانونية لأي أحداث تمس موضوع معلومات الشخصية لمستخدمي التطبيق"`,
      },
      {
        header: 'جمع البيانات الشخصية أثناء تسجيل المستخدم في التطبيق ',

        text: `تقوم الشركة بجمع البيانات الشخصية للمستخدم بعد موافقة صريحة منه أثناء التسجيل الذي يحدث بعد تنزيل التطبيق. يمكن للمستخدم الوصول إلى محتوى شروط الاستخدام هذه قبل تسجيله في التطبيق. بقبول صريح لشروط الاستخدام وموافقة المستخدم الصريحة على جمع البيانات الشخصية ، يستمر التطبيق في جمع البيانات الشخصية للمستخدم اللازمة للاستخدام السليم للتطبيق يعد جمع البيانات التالية مطلبًا ضروريًا حتى يتسنى لك استخدام التطبيق الذي يمكن من خلاله التبرع بالأدوية من كيانات المنفعة العامة إلى المستخدمين.يتم ذكر بيانات المستخدم بنفسه وبموافقة صريحة أثناء تسجيله للطلب ، وفقًا للمؤشرات المذكورة أدناه
`,
      },
      {
        header: 'البيانات الشخصية التي نجمعها',
        text:
          'أثناء التسجيل ، يقوم المستخدم بإدخال البيانات التالية: البريد الإلكتروني ، والجنس ، وسنة الميلاد ، والمنطقة التي يبحثون فيها عن الدواء المقدم. وبعد ذلك ، يسجل المستخدم في التطبيق الدواء المطلوب الذي يطمح إليه للوصول إلى يتلقى التطبيق احتياجات الدواء التي سجلها المستخدم من أجل ربطها بنجاح بكيانات المنفعة العامة التي يمكن أن يتلقوا الدواء منها ، ولا تقوم الشركة بجمع البيانات بأي شكل من الأشكال. من المستخدمين الذين لم تتم الإشارة إليهم أعلاه.' },
      {
        header: 'حفظ أرشيف البيانات الشخصية للمستخدمين',
        text:
          'على وجه التحديد ، تحتفظ الشركة بأرشيف لجميع البيانات الشخصية المذكورة أعلاه وفقًا لأوامر قانون N. 4624/2019 حماية بيانات الشخصية واتفاقية الاتحاد الأوروبي 679/2016 لحماية البيانات الشخصية. لحفظ هذه  البيانات شرعت الشركة في الإعلان عن مبدأ حماية البيانات الشخصية واحترام كل التدابير القانونية والتقنية لتأمين وحماية هذه البيانات ، علاوة على ذلك ، تحتفظ الشركة بإذن لحفظ أرشيف البيانات الشخصية الحساسة التي نشرت مبدأ الحماية البيانات الشخصية مع رقم التسجيل  1790/2017.    ',
      },
      {
        header: 'حقوق المستخدم فيما يتعلق ببياناته الشخصية',
        text:
          `
          يحق للمستخدم تعديل أو تغيير أو تحديث البيانات الشخصية المعتمدة ، أو طلب الإزالة الكاملة والنهائية للبيانات الشخصية المسجلة في التطبيق في أي وقت ، من خلال نموذج الاتصال المحدد« نموذج الاتصال للبيانات الشخصية لل المستخدم ». لأي سؤال أو طلب أو مشكلة تتعلق بجمع البيانات الشخصية وتحريرها وحفظها ، يمكن للمستخدمين التواصل مباشرة مع هيئة حماية البيانات الشخصية على عنوان البريد الإلكتروني تقوم الشركة بإبلاغ المستخدمين في حالات انتهاك برنامج الأمان برسالة محددة يتم إرسالها إلى كل مستخدم  خلال 24 ساعة من إبلاغ الشركة عن الانتهاك والتي تبلغ صراحة عن البيانات الشخصية التي يتم انتهاكها والإجراءات التالية والتقنية التدابير التي اتخذت وفقا <a
class='default-link' href='mailto:dpo@givmed.org'>dpo@givmed.org</a> لذلك
`,
      },
      {
        header: 'استخدام بيانات المستخدم الشخصية من الأطراف الثالثة',
        text:
          'البيانات الشخصية التي يقرها المستخدم أثناء تسجيله ولاحقًا أثناء استخدام تطبيق تهدف فقط إلى حفظ تشغيل الخدمات ، وتنسيق التبرع من الأدوية المتبقية للمستخدم. لا يتم مشاركتها مع أطراف ثالثة غير معروفة ل المستخدم بدون إذنه ، نشير إلى أننا قد نشارك معلومات المستخدم مع الشركاء من أجل دعم التطبيق وبعد موافقة قاطعة من المستخدم الذي يتم تقديمه من خلال التطبيق ، بينما يقوم المستخدم بقبول شروط الاستخدام هذه أثناء إبرام يعطي تسجيله موافقة نهائية ومحددة ومجانية على جمع البيانات الشخصية المذكورة أعلاه والاحتفاظ بملفها لخدمة أهداف التطبيق وتشغيله ، مع الحفاظ على الحق في الوصول إلى اعتراضات التحرير وعرضها بعد تقديم الطلب فيما يتعلق التصحيح أو الاستخدام المؤقت أو الربط أو المشاركة أو الحذف الكامل والكامل ، بناءً على تصحيح أو استخدام مؤقت أو ملزم أو عدم المشاركة أو الحذف الكامل والكامل ، طبقًا للقرار رقم 4624/2019 ولائحة الاتحاد الأوروبي 679/2016 لحماية البيانات الشخصية',
      },
      {
        header: 'أجهزة الوصول والشبكات',
        text:
          'يكون المستخدم مسؤولاً عن ضمان الوصول المطلوب إلى شبكة البيانات من أجل استخدام التطبيق. أنت مسؤول عن الحصول على وتحديث الأجهزة أو الأجهزة المتوافقة المطلوبة للوصول إلى الخدمات والتطبيقات واستخدامها وترقيتهما المحلية / تحديث',
      },
      {
        header: 'الاشعارات ',
        text: `قد تقدم الشركة إخطارًا عامًا فيما يتعلق بطلبها عبر البريد الإلكتروني أو إلى عنوان بريدك الإلكتروني الموجود في حسابك. يمكنك الاتصال
بالشركة عبر رسالة مكتوبة أو في عنوان :  Viktoros Ougo 15 أو Metaxourgio أو Attica أو Greece <a class='default-link' href='mailto:hello@givmed.org'>hello@givmed.org</a>.
      ` },
      {
        header: 'حدود المسؤولية',
        text: 'تعتزم الشركة أن تنسق من خلال هذا التطبيق عملية التبرع بالأدوية بين المستخدم والمنظمات التطوعية ولا تتحمل أي مسؤولية عن التبرعات الدوائية التي ستحدث من خلال استخدام التطبيق. علاوة على ذلك ، فإن الشركة غير مسؤولة عن دقة البيانات والمعلومات التي يدخلها المستخدم ، وهو المسؤول الوحيد عن دقتها ، ولا تقدم الشركة في أي حال ضمانات تتعلق بجودة الأدوية المتبرع بها ولا تتحمل أي مسؤولية في حالة التلف الذي يلحق بثالث. أخيرًا ، لا تتحمل الشركة ، تحت أي ظرف من الظروف ، أي مسؤولية عن أي دعاوى ذات طبيعة قانونية أو مدنية أو جنائية أو عن أي ضرر ناتج عن مستخدمي التطبيق أو عن طريق أطراف ثالثة لأي سبب يتعلق لتشغيل أو استخدام هذا التطبيق وكامل عملية التبرع بالأدوية التي حدثت من خلالها',
      },
      {
        header: 'حالة الاستخدام',
        text: 'لا ينبغي استخدام هذا التطبيق من قبل الأشخاص الذين لم يبلغوا الثامنة عشرة من العمر. وقد تم النص صراحة على أن الشركة لا تتحمل أي مسؤولية فيما يتعلق بدقة تاريخ ميلاد المستخدم المبلغ عنه في وقت التسجيل ولن تكون مسؤولة عن استخدام التطبيق من قبل الأشخاص الذين لم يبلغوا سن 18',
      },
      {
        header: 'JURISDICTION',
        text: 'يعترف المستخدم صراحة أن الاختصاص الحصري واختصاص أي نزاع مع GIVMED ، هو من اختصاص محاكم أثينا ويقوم بتنفيذ القانون العام اليوناني',
      },
    ],
  },
  contact: {
    title: 'جهات الاتصال',
  },
  about: {
    title: 'تعلم عن الأدوية',
    content: [
        {
          question: 'What exactly is medicine?',
          answer: `<p>Medicine is substances, chemical or herbal, that have been scientifically proven to be effective and safe, and are taken or given to the body with the aim of:</p>
              <ul>
              <li>
                  The treatment of a disease or a pathological condition (e.g., antibiotics are taken to treat a bacterial infection).
              </li>
              <li>
                  Treating or relieving the symptoms of a chronic or non-chronic illness (e.g., asthma medications, painkillers, etc.)
              </li>
              <li>
                  Prevention of a disease (e.g., the flu vaccine).
              </li>
              <li>
                  Reducing the progression of a disease (e.g., drugs for dementia).
              </li>
              <li>
                  The diagnosis of a pathological condition (e.g., contrast tomography).
              </li>
              </ul><p>`,
        },
        {
          question: 'Prescription and non-prescription medicines',
          answer: `<p><b>Prescription medicines (SY.FA.)</b> are those that in order to buy you need to have a prescription from a doctor [1]</small>.</p>
              <b>Non-prescription drugs (NSAIDs)</b> are those that you can buy without the need for a doctor's prescription, are usually taken for a short period of time, and are mainly for mild conditions that you can deal with on your own (e.g., x. headache pills).
              <br><br> <p><small>[1] For the possibility of a free prescription, you can contact your Municipality to inform you if it is done in the Municipal Clinics and if it concerns only specific groups of the population or all citizens.</small></p>`,
        },
        {
          question: 'Original and generic medicines',
          answer: `<p><b>An original medicine</b> is one that is produced and sold by the pharmaceutical company that created it from the beginning. When a pharmaceutical company discovers a new medicine, it has for some years the exclusive rights to produce and sell it ("patent"), ie no other pharmaceutical company can manufacture and sell a corresponding medicine with the same active ingredient (the component of the medicine due to its action in the body (see "What is the active substance"). After some years (20-25) the patent expires, thus allowing other pharmaceutical companies to develop and sell medicines containing the same active substance as the original medicinal product and which are called generics.</p>
              <b>Generic medicines</b> meet the same strict quality, safety, and efficiency standards as the originals. In this case, many medicines with different brand names may be released, but the active substance they contain is the same (even the form and dosage). Generic medicines may differ from the original medicines in form, size, shape, color, packaging, and inactive components (called excipients), ie those components that do not contribute to the action of the medicine. However, they are used to treat the same conditions as the originals and are just as effective. Generic medicines cost less than the original ones because, obviously, the pharmaceutical companies that sell them have not spent money on their research and development. The decision about which medicine to take and whether it is original or generic should always be made under the guidance of your doctor or pharmacist.`,
          },
        {
          question: 'What is the active substance of a medicine?',
          answer: 'The active substance of medicine is the ingredient that allows it to have a therapeutic effect on the human body, that is, makes it effective in the treatment of the disease or condition for which we take it, or to have a medicinal effect, such as the medicines we take. to do a diagnostic test (eg the contrast medicine given before the CT scan). The dosage recommended by your doctor or pharmacist for each medicine is essentially the amount of active substance you need to take to be able to have the desired effect on improving your health. The warnings that exist, also, for possible side effects in all medicines, are those that can in some cases be caused by the active substance of each medicine. ',
        },
        {
          question: 'What are antibiotics and what is their correct intake?',
          answer: `<p><b>What are antibiotics?</b></p><p>Germs include bacteria, viruses, fungi, and parasites. Antibiotics have been developed to treat infections caused exclusively by bacteria by killing them or stopping them from growing. Antibiotics do not work against all bacteria, which means that not all of them are suitable for treating any type of bacterial infection. Some aim to treat a single class of bacteria, while others aim to treat more than one. However, antibiotics are by no means effective against infections caused by other types of germs, such as viruses (such as the flu or the common cold).</p>
          <p><b>What is antibiotic resistance (or antimicrobial resistance)?</b></p><p>Antibiotic resistance is not related to the human body, that is, our body does not become resistant to antibiotics. It means that the bacteria develop the ability to overcome the medicines that are designed to treat them, so they are not killed and continue to grow despite taking them. Consequently, the bacterial infection in our body is not cured. These bacteria were previously sensitive to the antibiotics that were created to fight them, but they eventually managed to genetically alter (mutate) and develop resistance to them.</p>
          <p><b>Why does antibiotic resistance occur?</b></p><p>Developing resistance of bacteria to certain antibiotics is a natural development that can occur. However, when antibiotics are taken unnecessarily, excessively, and inappropriately, this process becomes much faster and to a greater extent. That is, bacteria are created for which there are no antibiotics available that can treat them. This means that while in the past some infections were treated with certain antibiotics, now they are becoming dangerous to humans again, as the antibiotics that were available to treat them are no longer effective.</p>
          <b>What to do & not to do about antibiotics</b></p><p>The responsible and correct intake of antibiotics helps to reduce the phenomenon of resistance so that these medicines remain effective in the treatment of bacterial infections. That is why it is very important to follow the following basic instructions:</p>
          <p><ul>
              <li>
                  <b>Only take antibiotics when recommended by your doctor. Antibiotics can only be obtained with a prescription.</b>
              </li>
              <li>
                  Follow your doctor's instructions exactly for the type of antibiotic you will be taking, the dosage, and the duration you will be taking it.
              </li>
              <li>
                  Common infections caused by viruses (not bacteria), such as whooping cough, cough, sore throat, bronchitis, otitis, sinusitis, etc. They are NOT treated with antibiotics.
              </li>
              <li>
                  Never use antibiotics that you may have left at home from previous treatment or on the advice of relatives and friends. Only a doctor can decide if you should take an antibiotic. Taking antibiotics when you should not do more harm to your health than good.
              </li>
          </ul>,<p>`,
        },
        {
          question: 'What are medication compliance, drug overdose, sub-therapeutic dose, and multi pharmacies?',
          answer: `<p><b>Compliance with the medication:</b> To faithfully follow the medication prescribed by the doctor and the relevant instructions for taking it, even without the close supervision of the doctor.</p>
              <p><b>Medicine overdose or abuse:</b> Take a higher dose of medicine than your doctor prescribes and it is recommended that you take it. This can be done by mistake, for example in case someone forgets that he took the dose of the medicine and takes a second one.</p>
              <p><b>Hyper therapeutic dose:</b> Take less medicine than your doctor prescribes and it is recommended to take or skip doses.</p>
              <p><b>Multitherapy:</b> Taking multiple medications at the same time, prescription or not. It can lead to mistakes in taking and dosing and to harmful interactions between them.</p>`,
        },
        {
          question: 'What are the side effects or bad side effects of medicine?',
          answer: `<p>They are unwanted or unexpected symptoms that can be observed after using a medicine. These side effects can be mild or even severe to the point that they can be life-threatening (eg dizziness, nausea, confusion, headache, dry mouth, shortness of breath, rash, allergic reaction, damage to organs of the body, etc.). These include those known to be caused by the use of a medicament e.g., according to the approved indications (and therefore mentioned in the package leaflet of the medicine), and those caused by other events, such as overdose, misuse, abuse, etc. If you suspect that a medicine may have caused you some side effects contact your doctor or pharmacist directly for information.</p>
          <p><b>CAUTION: Do not stop your medication unless your doctor tells you to.</b></p>
          <p>It does not necessarily mean that the medicine or the active substance of the medicine causes the side effects or that the medicine cannot be used safely. Whether or not you will continue your medication and how this will be done will only be decided by your doctor. The National Medicines Agency (EOF) has created the "Yellow Card" as a means of gathering information on the side effects of drugs. The "Yellow Card" is accessible via the electronic link https://www.eof.gr/web/guest/yellowgeneral. If you want to alternatively fill in the "Yellow Card" in printed form, you can contact the Department of Side Effects of EOF (284 Mesogeion, 155 62, Athens or the contact numbers 213 2040380 and 213 2040337, Fax 210 6549585) or directly to your pharmacy. The "Yellow Card" can be completed by both health professionals and patients themselves. So do not hesitate to mention side effects that are unknown to you and are not listed in the package leaflet of this medicine or if you have any doubts about whether they are related to any medicine you are taking. The information you provide can be very important to make known a side effect of medicine so that steps can be taken to ensure that patients take it more safely and safely. If you have difficulty filling out the "Yellow Card" yourself, ask your doctor or pharmacist for guidance.</p>`,
        },
        {
          question: 'How can medicine provoke interactions?',
          answer: `<p><b>With other medicines</b></p><p>It occurs when two or more medicines react with each other and cause unexpected actions. If you are taking different medicines and / or you are being watched by more than one doctor, you should know both you and all of your medication that you are taking to avoid any interactions between medicines.</p>
          <p><b>With food / beverages</b></p><p>Food can affect how the body absorbs a medicine. Also, concomitant intake of alcohol and certain medicines can reduce their effectiveness and / or cause side effects such as fatigue and reduction in the speed of reflexes. So it is important to follow closely the instructions for taking a medicine you will receive from your doctor or pharmacist.</p>
          <p><b>With existing diseases</b><p><p>The existence of a disease may not allow certain medicinal products to be taken, as it can make it dangerous for your health. This means that your doctor is important to know, except all your medication, and your whole medical history.</p>`,
        },
        {
          question: 'How can I get my medicine right?',
          answer: `<p>Some medications will need to be taken for a period of time (until you are actually cured of the condition you are taking them for), while in other cases you may need to take them for the rest of your life (as is often the case with chronic illnesses, e.g..x. diabetes). Therefore, proper medication intake is very important for your health. If you do not follow your doctor or pharmacist's instructions on how to take a medicine, it may reduce its effectiveness and ultimately not help you or end up hurting your health.</p>
          <p>That is why it is very important to:</p>
          <p><ul>
            <li>
              take exactly the dose recommended by your doctor or pharmacist.
            </li>
            <li>
              take the medicine at the hours and days recommended by your doctor or pharmacist.
            </li>
            <li>
              take the treatment for the period of time recommended by your doctor or pharmacist (no more, no less)
            </li>
            <li>
              follow the instructions for taking the medicine given to you by your doctor or pharmacist as to whether or not you should take this medicine with or without food and if you should avoid taking certain foods or drinks for as long as you take it.
            </li>
            <li>
              do not take expired medicines.
            </li>
            <li>
              keep in mind that some medicines, if you open the package (eg eye drops), then expire earlier than the expiry date stated on the package (in which case the expiry date after opening is indicated on the package leaflet medicine).
            </li>
            <li>
              talk to your doctor or pharmacist for guidance on what to do if you miss a dose, do not decide for yourself.
            </li>
            <li>
              do not crush tablets to make powder or open capsules to make them easier to swallow unless your doctor or pharmacist tells you that this is safe for this medicine.
            </li>
            <li>
              do not ignore an unexpected side effect that you think may be related to a medicine you are taking, contact your doctor or pharmacist directly.
            </li>
          </ul></p>
          <p>It is also important not to give friends and relatives medicines prescribed by your doctor for you, as you may not know exactly what they are suffering from and whether the medicine you are taking will help or harm them. Only the doctor who examines them can decide which medicine they should take, what is the right dose, and for how long they should take it. Similarly, do not use medicines that have been prescribed for your relatives or friends. For more information see the section "What can you do with expired medications but no longer need them".</p>`,
        },
        {
          question: 'How to organize the medication I am taking?',
          answer: `
          <ul>
            <li>
              Use a medication adjustment box for the whole week, which will help you organize the medications you take on a daily basis. This box can be obtained from any pharmacy.
            </li>
            <li>
              If the time your doctor or pharmacist tells you to take a medicine coincides with another time, combine the two actions (eg take the medicine as soon as you brush your teeth every morning).
            </li>
            <li>
              Stick notes with reminders to take the medicine in places you see all the time, such as the bathroom mirror or the refrigerator door.
            </li>
            <li>
              Use a diary to keep track of each time you take your medicine and the dose you take.
            </li>
            <li>
              Make reminders on your cell phone to let you know the days and times you need to take your medicine.
            </li>
            <li>
              If possible, ask a family member or friend to help you, reminding you when you need to take your medicine.
            </li>
            <li>
              Talk to your doctor or pharmacist for guidance on what to do if you miss a dose, do not decide for yourself.
            </li>
            <li>
              Do not crush tablets to make powder or open capsules to make them easier to swallow unless your doctor or pharmacist tells you that this is safe for this medicine.
            </li>
            <li>
              Do not ignore an unexpected side effect that you think may be related to a medicine you are taking, contact your doctor or pharmacist directly.
            </li>
          </ul>
          `,
        },
        {
          question: 'What do I do before I start taking medicine?',
          answer: `<p>Inform your doctor or pharmacist:</p>
          <p><ul>
            <li>
              For any other medicines, you are taking, even if they are herbal, vitamins, or dietary supplements. Ask if you can take them with your new medicine or if you need a change. You will thus be able to avoid any interactions between the various medications that may be detrimental to your health.
            </li>
            <li>
              If you have a proven allergy to certain medications.
            </li>
            <li>
              If you have a surgery scheduled.
            </li>
            <li>
              If you are pregnant or breastfeeding.
            </li>
            <li>
            If there is something you do not understand about the purpose, method of administration, dosage, or any other relevant information about the medicine.
            </li>
          </ul></p>
          <p>To have a good picture of all the medicines you use, but also to be able to inform your doctor or pharmacist at any time, it is very useful to make a list of all the medicines you are taking. A table, ie for each medicine that could have columns:</p>
          <ol>
            <li>
              Drug Name
            </li>
            <li>
              Why am I taking the medicine?
            </li>
            <li>
              Form (eg pill, drops, syrup, patch, injection, etc.).
            </li>
            <li>
              Dose (eg 1mg, 20ml, etc.).
            </li>
            <li>
              How often should I take it & how many doses.
            </li>
            <li>
              Date I started taking it.
            </li>
            <li>
              Date I should stop taking it (if I have a doctor or pharmacist).
            </li>
            <li>
              Other instructions on how to take it (eg with food or fasting / or should not drink alcohol, etc.).
            </li>
          </ol>`,
        },
        {
          question: 'What should I ask my doctor or pharmacist before I start taking medicine?',
          answer:
          `<ul>
              <li>
                  What does this medicine do?
              </li>
              <li>
                  What will it help me with?
              </li>
              <li>
                  Why should I take it?
              </li>
              <li>
                  Does it replace any of the medications I am already taking?
              </li>
              <li>
                  When and how should I take it? How much will I get each time?
              </li>
              <li>
                  How long will it take me to get it?
              </li>
              <li>
                  Can I chew, crush or melt this medicine to get it?
              </li>
              <li>
                  When will I start to see some improvement? How do I know I'm getting better?
              </li>
              <li>
                  Do I need to do any tests to see if the medicine really helps me? If so, what are these tests and when should I take them?
              </li>
              <li>
                  What should I do if I miss a dose?
              </li>
              <li>
                  What should I do if I accidentally take more medicine than I should?
              </li>
              <li>
                  Are there any possible side effects of taking this medicine?
              </li>
              <li>
                  Do I need to avoid certain medications or supplements for as long as I take this medication?
              </li>
              <li>
                  Do I need to avoid certain foods, drinks, or activities (eg driving) for as long as I take this medicine?
              </li>
              <li>
                  What is the best way to store this medicine?
              </li>
              <li>
                  Is there a case for me to develop tolerance or dependence on this particular drug?
              </li>
              <li>
                  Does my insurance cover this medicine?
              </li>
          </ul>`,
        },
        {
          question: 'How do I store my medicines?',
          answer: `<p>Each medicine is different, so always ask your doctor or pharmacist how to store it and read the package leaflet (FOX) carefully.</p>
          <ul>
            <li>
              As a general guideline, store medicines in a cool, dry place out of direct sunlight, as they can be harmful. That's why it's not good to keep medicines in the bathroom, kitchen, or car, where it is hot and humid.
            </li>
            <li>
              Some medicines may need to be stored in the refrigerator. Especially insulin, vaccines, and some eye drops and syrups require specific temperature conditions not only when stored but also when transported from the pharmacy to your home. Therefore, for these classes of medicines, follow faithfully and with special care the instructions that the pharmacist will give you both for their transport and their storage.
            </li>
            <li>
              Always keep the medicines in their original packaging, because it has the expiration date of the medicine on it and the package leaflet inside.
            </li>
            <li>
              Do not put different medicines in the same container.
            </li>
            <li>
            <p>Do not use pills or tablets if they are damaged, ie:</p>
              <ol>
                <li>
                  are fragile or have disintegrated.
                </li>
                <li>
                  have discolored.
                </li>
                <li>
                  are soft (while not normally so).
                </li>
                <li>
                  creams that have changed their texture, color, and/or smell bad.
                </li>
                <li>
                  Keep medicines in a safe place out of the reach and sight of children (see Parenting Tips).
                </li>
            </li>

          `,
        },
        {
          question: 'What should parents be aware of about their medications and children?',
          answer: `<p><b>Put the medicines and vitamins you have at home in a safe place that children do not see and cannot reach.</b></p><p>Find such a place in your home and store there all the medicines and vitamins you have, even the ones you use daily. Do not scatter them in different parts of the house and do not leave them in common view and in places that could be reached by small children. The medicines we leave on benches, tables, nightstands, bags, or wallets can be easily accessed by young children, who have an innate curiosity about everything around them and put all sorts of things in their mouths.</p>
          <p><b>If there is a safety cap on the package, make sure you always close it properly after each use.</b></p><p>If there is a safety cap on the medicine package, turn it over until you hear a "click" feature. Even so, this does not mean that children could not open the package. So again even these medicines should be kept together with all the rest away from children, as mentioned above, and never given to them to keep as a toy even if it is for a while.</p>
          <p><b>Pay special attention to the dosages of the medicines you give to the children.</b></p><p>You are well aware of the dosage of the medicine you are going to give your child. Follow the instructions of your pediatrician or pharmacist carefully and read the package leaflet carefully. If the medicine is in liquid form, there is usually a dose-measuring instrument (teaspoon, cup, or syringe) already in the package. If not, ask your pharmacist to give you one that is right for you. Do not use spoons, as they come in different sizes and are not suitable for this purpose, so you may end up giving your child a smaller or larger dose of medicine than they should. If the child is unable to take the full dose at some point (because he is crying, spitting on some of it, vomiting after a while, etc.), do not give him a second dose directly even if you have doubts about whether managed to get the dose he needed. Talk to your pediatrician to guide you on what is best to do in each case.</p>
          <p><b>Discuss with your children about medicines and how they are used safely</b></p><p>Discuss with your children and explain calmly and logically what the medicine is, why we take it and why they should only take it when given to them by an adult in the family. Do not tell your children that medicines are candies to persuade them to take them. Many drugs are brightly colored and may look like candy to their eyes, making the test even more tempting. Teach older siblings that they should never give medication to younger siblings before first talking to an adult in the family.</p>
          <p><b>Ask guests and guests to follow the same rules.</b></p><p>Remind guests and guests who have medicines with them that they should also keep them, while they are at home, in a safe place that small children do not see or reach.<p>
          <p><b>Be prepared in case of an emergency.</b></p><p>Always have the emergency number (166) and the Poison Center (2107793777) available and call immediately if you think your child may have taken any medicine or vitamins on their own.</p>`,
        },
        {
          question: 'What should people taking care of other people`s medicines watch out for?',
          answer: `<p><b>Get to know all the medication he is taking and plan how he will take it.</b></p><p>Make a list of all prescription and over-the-counter medications, including herbal remedies and supplements. If for some reason you do not take a dose of the drug, do not give a double later or try to make up for it in some way. Talk to your doctor or pharmacist for advice on what to do in each case depending on your medication. Use a medication adjustment box for the whole week, which will help you organize daily the medications that the person you care for needs to take. You can also use a diary to more easily remember when to take each medication and dosages, as well as important appointments with the attending physicians. Medications should also be given at certain times of the day and in some cases should be combined with food, while others should not. If you do not follow the exact instructions of your doctor or pharmacist to take the medicine correctly, its therapeutic effects may be reduced.</p>
          <p><b>Seek regular communication and cooperation with your doctor and pharmacist.</b></p><p>For any new medicine, you need to take, first ask your doctor or pharmacist if it is safe in combination with all the other medicines he is taking. Important questions to ask your doctor or pharmacist about any new medicine you are about to take are:</p>
          <p><ul>
            <li>
              What is the purpose of this medicine?
            </li>
            <li>
              What improvement in the health of the person I take care to expect and in how long will it happen?
            </li>
            <li>
              When and how should he take this medicine, what is the dose, and for how long will he take it?
            </li>
            <li>
              What do I do if I miss one or more doses?
            </li>
            <li>
              Can he take this medicine in combination with the rest he is taking?
            </li>
            <li>
              Are there any possible side effects that may occur and what are they?
            </li>
            Make sure you keep in touch with the doctors who are watching him/her and arrange the appointments he/she needs with them so that they can review the medication at regular intervals and adjust it if necessary. For long-term medication, be sure to renew your prescriptions in time before your home medication is completely exhausted so that you do not miss any of the doses. At your doctor's appointment, discuss whether the person you are caring for is improving and if he or she has had side effects or new symptoms that he or she has not had before.
          </ul></p>
          <p><b>ΤWhat to do if he refuses to take the medicine.</b><p><p>If the person you take care, refuses to take his medication, try to understand exactly where the problem lies. In other words, this medicine may cause some side effects that it wants to avoid or it may be difficult for you to swallow a large pill. Do not try to give him the medicine by force. Avoid crushing tablets to turn them into powder or opening capsules to mix them with a drink or food, as this may not be safe. Consult your doctor or pharmacist about what to do in these cases. For example, they may suggest another drug that is just as effective but is in liquid form, so that it is easier to swallow.</p>`,
        },
        {
          question: 'Where do I throw my medicines when they expire?',
          answer: `<p>Expired medicines should NOT be discarded:</p>
          <p><ul>
            <li>
              in the neighborhood rubbish bins
            </li>
            <li>
              in the sink
            </li>
            <li>
              in the toilet bowl,
            </li>
          </ul></p>
          <p>because the chemicals they contain pollute both the soil and the water in the environment, posing a significant risk to everyone's health. Expired medicines should only be thrown away in the green metal bins found in pharmacies. There they are temporarily collected and handed over to the Institute of Pharmaceutical Research and Technology (IFET) to be safely destroyed.</p>
          <p><b>What can you throw in the green bins of pharmacies?</b></p>
          <p><ul>
            <li>
              Pills and capsules in the foil containing them (paper and plastic packaging of medicines should be disposed of in recycling bins).
            </li>
            <li>
              Well-sealed glass bottles of syrups and vials for injection.
            </li>
            <li>
              Tubes with ointments and creams.
            </li>
          </ul></p>
          <p><b>What you should NOT throw in the green bins of pharmacies.</b></p>
          <ul>
            <li>
              Mercury (eg thermometers)
            </li>
            <li>
              Syringes
            </li>
            <li>
              Cracked injection vials
            </li>
            <li>
              Broken syrup bottles
            </li>
            <li>
              Gauze
            </li>
            <li>
              Cotton
            </li>
          </ul>`,
        },
        {
          question: 'What should I do with my surplus medications?',
          answer: `<p>If you have medicine that is about to expire or you have leftovers because your treatment is over, you can donate them to other people who need them, even if you have a small amount left.</p>
          <p>Το <b>GIVMED</b> is a non-profit organization that aims to give people immediate access to the medicines they need. You can easily and quickly donate the medicines you no longer need to social pharmacies, nursing homes, and other charities.</p>
          <p>At the <b>GIVMED</b> website <a href="https://givmed.org/" target="_blank">(https://givmed.org/)</a> you can find the charities that are near you and need the medicines you wish to donate. If you are unfamiliar with the technology, you can call GIVMED (210 3007222) for directions.</p>
          It is important that the medicine or medicines you wish to donate meet the following conditions:
          <p><ul>
            <li>
              The expiration date has not passed.
            </li>
            <li>
            It is not a medicine that needs a refrigerator.
            </li>
            <li>
            It is not open if it is a syrup, ointment, drops, or inhaled product.
            </li>
            <li>
              It does not belong to the category of digram drugs.
            </li>
            <li>
              It has been stored at a controlled room temperature of 25 degrees and away from any kind of heat or humidity source.
            </li>
            <li>
              The medicine voucher (i.e. the barcode sticker) has been removed.
            </li>
          </ul></p>
          <p><b>Do not forget that medicine is a good to which all people have the right to be able to take care of their health in the best possible way. If you have any further questions or concerns, do not hesitate to talk to your doctor and pharmacist, who are best placed to assist you and guide you through what you need to know about your medication and take care of your health.</b></p>`,
        },
      ],
  },
  logout: {
    title: 'خروج',
  },
  weekdays: {
    monday: 'الأثنين',
    tuesday: 'الثلاثاء',
    wednesday: 'الأربعاء',
    thursday: 'الخميس',
    friday: 'الجمعة',
    saturday: 'السبت',
    sunday: 'الأحد',
  },
  quantityType: {
      BOX: 'علب | علب',
      BAG: 'أكياس | أكياس',
      PIECE: 'قطع ( التصنيف الفرعي للعلبة) | قطع ( التصنيف الفرعي للعلبة)',
      OTHER: 'آخر | آخر',
      ML: 'ml',
      pieces: 'قطع | قطع',
      subUnit: '( التصنيف الفرعي للعلبة) | ( التصنيف الفرعي للعلبة)',
  },
  quantityTypePlural: {
      BOX: 'علب',
      PIECE: 'قطع ( التصنيف الفرعي للعلبة)',
      OTHER: 'آخر',
  },
  labels: {
    lang: 'اللغة',
    address: 'تبوك',
    phone: 'الهاتف',
    medName: 'اسم الدواء',
    medSubs: 'اسم الدواء البديل  ',
    hygProdName: 'اسم المنتج الصحي',
    hygProdCategoryOrSubCategory: 'اسم الصنف | الصنف الفرعي',
    subCategory: 'الصنف الفرعي',
    expDate: 'تاريخ الانتهاء',
    quantity: 'الكمية',
    productUnit: 'وحدة المنتج',
    needDate: 'تاريخ التسجيل',
    concentration: 'عيار الدواء',
    boxes: 'علب | علب',
    newSearch: 'بحث جديد',
    newNeed: 'اضف طلب جديد ',
    checkMore: 'شاهد المزيد',
  },
  organizationDocuments: {
    ekkatharistiko: 'براءة الذمة الضريبية',
    apodeiktikoKatoikias: 'إثبات الإقامة (مثل حساب DEKO ، الإيجار)',
    sintagi: 'الوصفة الطبية ',
    pistopoihtikoOikogenneiakisKatastasis: 'شهادة الوضع العائلي',
    pistopoihtikoEualototitas: 'أي شهادة من حالات الضعف (مثل معدل الإعاقة ، والأسر الكبيرة ، وما إلى ذلك)',
    tautotita: 'نسخة من الهوية أو جواز السفر',
    forologikiDilosiE1: 'تطبيق الضريبة السنوية (E1)',
    kartaAnergias: 'كرت العاطل عن العمل ',
    dilosiAkinitisPeriousiasE9: 'إعلان العقارات (E9)',
    Asfalisi: 'إعلان رسمي بعدم وجود تأمين في أي من مؤسسات التأمين. بالنسبة للأشخاص المؤمن عليهم ، يجب تقديم الصفحة الأولى من كتيب التأمين الخاص بهم ',
    kEA: 'إذا كان المريض مستفيدًا من دخل التضامن الاجتماعي (ايكا) ، فلن يلزم سوى تقديم بطاقة هويته وطلب اعتماد المعتمد من (ايكا ',
    aitisi: 'الطلب (الصادر والمكتمل في المؤسسة',
    adeiaParamonis: 'تصريح إقامة ساري المفعول (للأجانب)',
    vevaiosiDimou: 'في حالة غير المقيمين ، يجب الحصول على شهادة من البلدية تثبت عدم حصولهم على برنامج مماثل.',
    sintaxiMisthodosia: ' شهادة راتب ',
    fotografia: 'صورة (نمط جواز السفر)',
    pistopoihtikoGennisis: 'او شهادة الميلاد (للقاصرين "AFM" اما',
  },
  newAppVersionAvailable: {
    text: 'الإصدار الجديد متوفر! انقر للتحديث',
    btn: 'تحديث',
  },
  offlineMode: {
    text: 'انت غير متصل بالشبكة',
  },
  iosInstallBanner: {
    text: 'تثبيت MEDforU على هاتفك. اضغط على زر المشاركة ثم حدد" إضافة إلى الشاشة الرئيسية ".',
  },
  errorCodes: {
    serverConnectionProblem: 'غير قادر على الاتصال بالخدمة , الرجاء التواصل مع فريقنا !',
    generalValidationError: 'إدخال خاطئ.',
    multipleObjectsReturned: 'مواد متعددة اعيدت ',
    accountDisabled: 'تم تعطيل حساب المستخدم.',
    userAlreadyExists: 'المستخدم بهذا البريد الإلكتروني موجود بالفعل.',
    wrongCredentials: ' البريد الإلكتروني أو كلمة المرور غير صحيحة',
    validationErrorException: 'حدث استثناء',
    invalidToken: 'رمز غير صالح',
    wrongCurrentPassword: 'كلمة المرور الحالية خاطئة',
    authenticationFailed: 'المصادقة فشلت',
    coveredQuantity: 'الكمية المغطاة أكبر من الكمية المحتاجة.',
    sendEmailException: 'حدث خطأ أثناء محاولة إرسال بريد إلكتروني إلى المستخدم',
    transactionIntegrityError: 'خطأ في سلامة المعاملات',
    productNotFound: 'This product is not founded.',
    productNotMatchedWithYourNeeds: 'This product is not matched with your needs.',
    requestedReservedQuantityGreaterThanPermitedAvailableQuantity: 'Requested reserved quantity of this product is greater than permitted available quantity.',
    noEmailSetForThisOrganization: 'There is not email set for this organization.',
  },
};
