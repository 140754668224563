import mainConfig from './routes.config';
import { routeBasics } from '../../utils/routes.util';

export const mainRoutes = {
    ...routeBasics(mainConfig),
    component: () => import('../../views/layouts/layout.main.vue'),
    children: [
        {
            ...routeBasics(mainConfig.needs.add),
            component: () => import('../../views/main/needs/add/Index.vue'),
        },
        {
            ...routeBasics(mainConfig.needs.add.medNeeds),
            component: () => import('../../views/main/needs/add/Meds.vue'),
        },
        {
            ...routeBasics(mainConfig.needs.add.medSubstanceNeeds),
            component: () => import('../../views/main/needs/add/MedSubstances.vue'),
        },
        {
            ...routeBasics(mainConfig.needs.add.hygProdNeeds),
            component: () => import('../../views/main/needs/add/HygProds.vue'),
        },
        {
            ...routeBasics(mainConfig.needs.add.hygProdCategoryNeeds),
            component: () => import('../../views/main/needs/add/HygProdCategory.vue'),
        },
        {
            ...routeBasics(mainConfig.needs.basket),
            component: () => import('../../views/main/needs/Basket.vue'),
        },
        {
            ...routeBasics(mainConfig.needs.AutoMatchWithOrganizationProducts),
            component: () => import('../../views/main/needs/AutoMatchWithOrganizationProducts.vue'),
        },
        {
            ...routeBasics(mainConfig.needs.panel),
            component: () => import('../../views/main/needs/Pending.vue'),
        },
        {
            ...routeBasics(mainConfig.needs.satisfied),
            component: () => import('../../views/main/needs/Satisfied.vue'),
        },
        {
            ...routeBasics(mainConfig.map),
        },
        {
            ...routeBasics(mainConfig.profile),
            component: () => import('../../views/main/Profile.vue'),
        },
        {
            ...routeBasics(mainConfig.contact),
            component: () => import('../../views/global/Contact.vue'),
        },
        {
            ...routeBasics(mainConfig.terms),
            component: () => import('../../views/global/Terms.vue'),
        },
        {
            ...routeBasics(mainConfig.faq),
            component: () => import('../../views/global/Faq.vue'),
        },
        {
            ...routeBasics(mainConfig.tutorial),
            component: () => import('../../views/global/Tutorial.vue'),
        },
        {
            ...routeBasics(mainConfig.about),
            component: () => import('../../views/global/About.vue'),
        },
    ],
};
