import moment from 'moment';
import { reactive, computed, toRefs } from 'vue';
import { nonMedforuApiAxos } from '../utils/axios.util';
import localforage from 'localforage';
import workbox from '../registerServiceWorker';
import i18nInstance from '../plugins/i18n';
import vuetifyInstance from '../plugins/vuetify';
import { LangIsoEnum, LangNameEnum } from '../constants';

const SETTINGS_STORE_ACTIVE_LANG_KEY = 'activeLang';
const settingsStore = localforage.createInstance({
    name: 'SettingsStore',
});

/** @type {MedForU.Lang} */
const english = {
    iso: LangIsoEnum.En,
    name: LangNameEnum.En,
    isRtl: false,
};

/** @type {MedForU.Lang} */
const greek = {
    iso: LangIsoEnum.El,
    name: LangNameEnum.El,
    isRtl: false,
};

/** @type {MedForU.Lang} */
const french = {
    iso: LangIsoEnum.Fr,
    name: LangNameEnum.Fr,
    isRtl: false,
};

/** @type {MedForU.Lang} */
const farsi = {
    iso: LangIsoEnum.Fa,
    name: LangNameEnum.Fa,
    isRtl: true,
};

/** @type {MedForU.Lang} */
const arabic = {
    iso: LangIsoEnum.Ar,
    name: LangNameEnum.Ar,
    isRtl: true,
};

/** @type {MedForU.Lang} */
const ukrainian = {
    iso: LangIsoEnum.Uk,
    name: LangNameEnum.Uk,
    isRtl: false,
};

/** @type {MedForU.Langs} */
const langs = [
    english,
    greek,
    french,
    farsi,
    arabic,
    ukrainian,
];

/** @returns {MedForU.Notification} */
const getNotificationInitialState = () => {
    return {
        show: false,
        status: 'ERROR',
        text: '',
    };
};

/** @returns {boolean} */
const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    return /iphone|ipad|ipod/.test(userAgent);
};

// Detects if device is in standalone mode
/** @returns {boolean} */
// @ts-ignore
const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

/** @type {import('vue').UnwrapRef<MedForU.SettingsState>} */
const settingsState = reactive({
    langs,
    activeLang: english,
    notification: getNotificationInitialState(),
    newAppVersionAvailable: false,
    isNavDrawerOpen: false,
    iosAddToHomescreen: isIos() && !isInStandaloneMode(),
    userWayInstance: null,
    regionalUnities: [],
    isGreekLang: computed(() => settingsState.activeLang.iso === greek.iso),
});

export default function useSettings() {
    const initSettingsModule = async() => {
        /*
            NOTE: We run the command _setUserWayWidgetLocale twice to make sure the code works properly
            regardless of the execution time of await and 'userway:init_completed'
        */

        if (workbox) {
            workbox.addEventListener('waiting', () => {
                console.info('New app version is available for download.');
                settingsState.newAppVersionAvailable = true;
            });
        }

        document.addEventListener('userway:init_completed', (event) => {
            // @ts-ignore
            settingsState.userWayInstance = event.detail.userWayInstance;

            _setUserWayWidgetLocale(settingsState.activeLang.iso);
        });

        settingsState.activeLang = await settingsStore.getItem(SETTINGS_STORE_ACTIVE_LANG_KEY) || settingsState.activeLang;

        _setUserWayWidgetLocale(settingsState.activeLang.iso);

        _setI18nInstanceLocale(settingsState.activeLang.iso);

        /*
            NOTE3: The code below won't work as default vuetify options overwrite it. Check main.js.
            vuetifyInstance.framework.rtl = settingsState.activeLang.isRtl;
        */

        await getRegionalUnities();
    };

    /** @param {MedForU.Lang} lang */
    const setLang = async(lang) => {
        settingsState.activeLang = lang;
        _setI18nInstanceLocale(settingsState.activeLang.iso);
        _setUserWayWidgetLocale(settingsState.activeLang.iso);
        vuetifyInstance.framework.rtl = lang.isRtl;

        await settingsStore.setItem(SETTINGS_STORE_ACTIVE_LANG_KEY, lang);
    };

    /** @param {MedForU.LangIso} langIso */
    const _setI18nInstanceLocale = (langIso) => {
        i18nInstance.locale = langIso;
    };

    /** @param {MedForU.LangIso} langIso */
    const _setUserWayWidgetLocale = (langIso) => {
        // Userway does not provide an API function to change the widget's position like changeWidgetLanguage
        settingsState.userWayInstance?.changeWidgetLanguage(langIso);
    };

    /** @param {MedForU.LangIso} langIso */
    const getLangFromIso = (langIso) => {
        return settingsState.langs.find(lang => lang.iso === langIso) ?? settingsState.activeLang;
    };

    /**
    * @param {string} translation
    * @param {any} [values]
    * @returns {import('vue-i18n').TranslateResult}
    */
    const getTranslation = (translation, values) => {
        const locale = settingsState.activeLang.iso;

        return i18nInstance.t(translation, locale, values);
    };

    /** @param {MedForU.NotificationData} notification */
    const notify = (notification) => {
        settingsState.notification.show = true;
        settingsState.notification.text = notification.text;
        settingsState.notification.status = notification?.status ?? 'SUCCESS';
        settingsState.notification.ms = notification?.ms;
    };

    const dismissGenericNotification = () => {
        settingsState.notification = getNotificationInitialState();
    };

    const dismissIosAddToHomescreenNotification = () => {
        settingsState.iosAddToHomescreen = false;
    };

    const downloadNewAppVersion = async() => {
        if (!workbox) {
            console.error('Service worker not found on window.');
            return;
        }

        settingsState.newAppVersionAvailable = false;

        await workbox.messageSW({ type: 'SKIP_WAITING' });
    };

    /** @param {boolean} value */
    const setNavDrawer = (value) => {
        settingsState.isNavDrawerOpen = value;
    };

    /** @param {Date} date */
    const getDateFormatAccodingToActiveLang = (date) => {
        return settingsState.isGreekLang ? moment(date).format('DD-MM-YYYY') : moment(date).format('YYYY-MM-DD');
    };

    const getRegionalUnities = async() => {
        try {
            const regionalUnitiesUrl = (settingsState.isGreekLang ? process.env.VUE_APP_REGIONAL_UNITIES_API_EL_URL : process.env.VUE_APP_REGIONAL_UNITIES_API_EN_URL) ?? '';

            /** @type {import('axios').AxiosResponse<MedForU.RegionalUnities> } */
            const response = await nonMedforuApiAxos.get(regionalUnitiesUrl);

            settingsState.regionalUnities = response.data;
        } catch (error) {
            // @ts-ignore
            notify({ text: error, status: 'ERROR' });
        }
    };

    /**
    * @param {MedForU.RegionalUnity} value
    * @returns {string}
    */
    const getRegionalUnityRegionRepresentation = (value) => {
        return `${value.name} - ${value.region_name}`;
    };

    const refsObject = toRefs(settingsState);

    return {
        ...refsObject,
        initSettingsModule,
        setLang,
        notify,
        getLangFromIso,
        getTranslation,
        downloadNewAppVersion,
        dismissGenericNotification,
        dismissIosAddToHomescreenNotification,
        setNavDrawer,
        getDateFormatAccodingToActiveLang,
        getRegionalUnities,
        getRegionalUnityRegionRepresentation,
    };
}

