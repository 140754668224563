import axios from 'axios';
import jsCookie from 'js-cookie';
import useUsers from '../modules/users.module';
import useSettings from '../modules/settings.module';
import { NotificationStatus } from '../constants';

const nonMedforuApiAxos = axios.create({});

const medforuAPIAxiosOptions = {
    baseURL: process.env.VUE_APP_BASE_API_URL,
    withCredentials: true,
};

// medforuAPIAxiosUninterceptedResponse

const medforuAPIAxiosUninterceptedResponse = axios.create(medforuAPIAxiosOptions);

medforuAPIAxiosUninterceptedResponse.interceptors.request.use(config => {
    const csrfCookieValue = jsCookie.get(process.env.VUE_APP_CSRF_COOKIE_NAME);

    if (csrfCookieValue) {
        config.headers['X-CSRFToken'] = csrfCookieValue;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

// medforuAPIAxios

const medforuAPIAxios = axios.create(medforuAPIAxiosOptions);

medforuAPIAxios.interceptors.request.use(config => {
    const csrfCookieValue = jsCookie.get(process.env.VUE_APP_CSRF_COOKIE_NAME);

    if (csrfCookieValue) {
        config.headers['X-CSRFToken'] = csrfCookieValue;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

medforuAPIAxios.interceptors.response.use(response => response, async error => {
    const { notify, getTranslation } = useSettings();
    const { logout } = useUsers();

    let errorMessage = '';

    if (error?.response.status === 400) {
        errorMessage = error?.response.data.error_code ? `errorCodes.${error?.response.data.error_code}` : 'errorCodes.generalValidationError';
    } else if (error?.response.status === 401 || error?.response.status === 403) {
        errorMessage = 'errorCodes.authenticationFailed';

        await logout();
    } else if (error?.response.status === 500) {
        errorMessage = 'errorCodes.serverConnectionProblem';
    }

    const notificationPayload = {
        text: getTranslation(errorMessage),
        status: NotificationStatus.Error,
    };

    notify(notificationPayload);

    return null;
});

export { medforuAPIAxiosUninterceptedResponse, medforuAPIAxios, nonMedforuApiAxos };
