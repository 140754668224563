<template>
    <v-snackbar
        v-model="newAppVersionAvailable"
        bottom
        multi-line
        :timeout="-1"
        color="primary white--text"
    >
        {{ $t("newAppVersionAvailable.text") }}

        <template #action="{ attrs }">
            <v-btn
                v-bind="attrs"
                class="orangeLike white--text"
                @click="downloadNewAppVersion"
            >
                <span class="text-none">
                    {{ $t("newAppVersionAvailable.btn") }}
                </span>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { defineComponent } from 'vue';
import useSettings from '../../modules/settings.module';

export default defineComponent({
    setup() {
        const { newAppVersionAvailable, downloadNewAppVersion } = useSettings();

        return {
            newAppVersionAvailable, downloadNewAppVersion,
        };
    },
});
</script>
