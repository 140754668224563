import useSettings from '../modules/settings.module';

export default {
    data() {
        return {
            recaptchaSiteKey: process.env.VUE_APP_GRECAPTCHA,
            quantityPreFixedValues: Array.from(Array(10), (_, i) => i + 1),
        };
    },
    computed: {
        /** @returns {boolean} */
        isNeedBySubstanceGlobal() {
            return this.need?.eofcode_field === 'S';
        },
        /** @returns {string} */
        needsPanelModaltitle() {
            return this.isNeedBySubstanceGlobal ? this.need?.medSubs : this.need?.medName;
        },
        /** @returns {boolean} */
        isScreenMobile() {
            return this.$vuetify.breakpoint.xsOnly;
        },
        /** @returns {boolean} */
        isScreenSmallAndSmaller() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        /** @returns {boolean} */
        isScreenMediumAndSmaller() {
            return this.$vuetify.breakpoint.mdAndDown;
        },
        /** @returns {boolean} */
        isScreenDesktopAndLarger() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        /** @returns {boolean} */
        isScreenMediumAndLarger() {
            return this.$vuetify.breakpoint.mdAndUp;
        },
    },
    methods: {
        /** @param {MedForU.NotificationData} noticationData */
        $notify(noticationData) {
            // NOTE: we do we need parenthesis for useSettings()?
            (useSettings()).notify(noticationData);
        },
        /** @param {[]} allRoutes */
        possibleMenuRoutes(allRoutes) {
            let possibleMenuRoutes = [];

            allRoutes.forEach(route => {
                if (!route.children) {
                    return;
                }

                possibleMenuRoutes = possibleMenuRoutes.concat(route);

                route.children.forEach(route => {
                    if (!route.children) {
                        return;
                    }

                    possibleMenuRoutes = possibleMenuRoutes.concat(route);
                });
            });

            return possibleMenuRoutes;
        },
        sortByMetaOrder(route1, route2) {
            return route1?.meta?.menu?.order - route2?.meta?.menu?.order ?? 0;
        },
        /** @param {string} ref */
        onCaptchaExpired(ref) {
            this.$refs?.[ref].reset();
        },
        /** @param {string} ref */
        captchaExecute(ref) {
            this.$refs?.[ref].execute();
        },
    },
    filters: {
        /** @param {string} str */
        upperCaseNoAccents(str) {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
        },
    },
};
