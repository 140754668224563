export default {
    path: '/auth',
    name: 'auth',
    lang: {
        path: 'lang',
        name: 'lang',
        meta: {
            requiresAuth: false,
            hasNavigationDrawer: false,
            canGoToPreviousPage: false,
        },
    },
    base: {
        path: 'base',
        name: 'base',
        meta: {
            background: 'primary',
            hasNavigationDrawer: false,
            requiresAuth: false,
        },
    },
    preSignup: {
        path: 'pre-signup',
        name: 'preSignup',
        meta: {
            requiresAuth: false,
            hasNavigationDrawer: false,
        },
    },
    login: {
        path: 'login',
        name: 'login',
        meta: {
            requiresAuth: false,
            pageTitle: 'login.title',
            menu: {
                hideWhenLoggedIn: true,
                label: 'login.title',
                button: {
                    color: 'secondary',
                },
                order: 20,
            },
        },
    },
    signup: {
        path: 'signup',
        name: 'signup',
        meta: {
            requiresAuth: false,
            pageTitle: 'signup.title',
            menu: {
                hideWhenLoggedIn: true,
                label: 'signup.title',
                button: {
                    color: 'primary',
                },
                order: 21,
            },
        },
    },
    resetPassword: {
        path: 'reset-password',
        name: 'resetPassword',
        meta: {
            requiresAuth: false,
            pageTitle: 'resetPassword.title',
            hasNavigationDrawer: false,
            canGoToPreviousPage: false,
        },
    },
    logout: {
        path: 'logout',
        name: 'logout',
        meta: {
            requiresAuth: true,
            pageTitle: 'logout.title',
            menu: {
                label: 'logout.title',
                button: {
                    color: 'orangeLike',
                },
                order: 22,
            },
        },
    },
};
