// @ts-nocheck
import Vue from 'vue';
import VueRouter from 'vue-router';

import authRoutesConfig from './auth/routes.config';
import mainRoutesConfig from './main/routes.config';
import { authRoutes } from './auth/routes';
import { mainRoutes } from './main/routes';
import useUsers from '../modules/users.module';

Vue.use(VueRouter);

const routes = [
    authRoutes,
    mainRoutes,
];

routes.push({
    path: '*',
    // @ts-ignore
    redirect: { name: mainRoutesConfig.name },
});

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

const authRoutesNames = Object.keys(authRoutesConfig).map(key => {
    if (key === 'name') {
        return authRoutesConfig[key];
    }

    if (authRoutesConfig[key] && authRoutesConfig[key].name) {
        return authRoutesConfig[key].name;
    }

    return;
}).filter(name => name);

function _pageRequiresLoggedInUser(to) {
    return to.matched.some(route => route.meta.requiresAuth);
}

async function _logoutOrContinue(to, next) {
    if (to.name === authRoutesConfig.logout.name) {
        await useUsers().logout();
    } else {
        next();
    }
}

function _skipAuthAndMainPageAndContinue(to, next) {
    if (authRoutesNames.includes(to.name || '') || (to.name === mainRoutesConfig.name)) {
        next({ name: mainRoutesConfig.needs.add.name });
    } else {
        next();
    }
}

router.beforeEach((to, from, next) => {
    const isUserLoggedIn = useUsers().isUserLoggedIn.value;

    if (_pageRequiresLoggedInUser(to)) {
        if (!isUserLoggedIn) {
            next({ name: authRoutesConfig.lang.name });
        } else {
            return _logoutOrContinue(to, next);
        }
    } else {
        if (!isUserLoggedIn) {
            if (to.name === mainRoutesConfig.name) {
                next({ name: authRoutesConfig.lang.name });
            } else {
                next();
            }
        } else {
            return _skipAuthAndMainPageAndContinue(to, next);
        }
    }
});

export default router;
