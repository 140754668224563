import { Workbox } from 'workbox-window';

let wb;

if ('serviceWorker' in navigator) {
    wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

    // Once a new service worker is installed and starts controlling the page
    wb.addEventListener('controlling', () => {
        window.location.reload();
    });

    wb.register();
} else {
    wb = null;
}

export default /** @type {Workbox | null} */ (wb);

