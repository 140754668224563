import { reactive, computed, toRefs } from 'vue';
import localforage from 'localforage';
import router from '../router';
import { medforuAPIAxios, medforuAPIAxiosUninterceptedResponse } from '../utils/axios.util';
import authRoutesConfig from '../router/auth/routes.config.js';
import mainRoutesConfig from '../router/main/routes.config.js';
import useSettings from './settings.module';

const USERS_STORE_USER_KEY = 'user';
const userStore = localforage.createInstance({
    name: 'UserStore',
});
const RESET_PASSWORD_URL = '/reset-password';

const { notify, getTranslation, setLang, getLangFromIso } = useSettings();

/** @type {import('vue').UnwrapRef<MedForU.UserState>} */
const userState = reactive({
    user: null,
    isUserLoggedIn: computed(() => !!userState.user?.beneficiary_user_id),
});

export default function useUsers() {
    const initUserModule = async() => {
        userState.user = await userStore.getItem(USERS_STORE_USER_KEY) || userState.user;
    };

    /**
    * @param {MedForU.AuthData} authData
    * @param {string} url
    * @param {string} routeToName
    * */
    const _auth = async(authData, url, routeToName) => {
        /** @type {import('axios').AxiosResponse<any>? } */
        const response = await medforuAPIAxios.post(url, {
            ...authData, 'g-recaptcha-response': authData.gRecaptchaResponse,
        });

        if (!response) {
            return;
        }

        userState.user = response.data;

        await userStore.setItem(USERS_STORE_USER_KEY, userState.user);

        router.push({ name: routeToName });
    };

    /** @param {MedForU.AuthData} loginData */
    const login = async(loginData) => {
        await _auth(loginData, '/login', mainRoutesConfig.needs.add.name);
    };

    /** @param {MedForU.AuthData} singupData */
    const signup = async(singupData) => {
        await _auth(singupData, '/signup', mainRoutesConfig.profile.name);
    };

    const _clearUserState = async() => {
        userState.user = null;

        await userStore.removeItem(USERS_STORE_USER_KEY);
    };

    const logout = async() => {
        /*
            Promise.all() -> It will reject immediately if any of the promises in the array is rejected. If all promises fulfill successfully, it resolves with an array of their results.
            Promise.allSettled() -> It waits for all promises to settle (either fulfill or reject) and then returns an array of objects

            NOTE: We used medforuAPIAxiosUninterceptedResponse because if medforuAPIAxios.post('/logout') fails with 403 (due to session expiration for example),
            an infinite call to logout endpoint will take place since this function calls the logout endpoint (which will trigger the interceptor in case of failure)
            and the interceptor will call this function again (in case of 403 error) and so on.
        */

        await Promise.allSettled([
            medforuAPIAxiosUninterceptedResponse.post('/logout'),
            _clearUserState(),
        ]);

        router.push({ name: authRoutesConfig.lang.name });
    };

    /** @param {MedForU.ChangePasswordData} changePasswordData */
    const changePassword = async(changePasswordData) => {
        /** @type {import('axios').AxiosResponse<any>? } */
        const response = await medforuAPIAxios.patch('/change-password', changePasswordData);

        if (!response) {
            return;
        }

        notify({ text: getTranslation('profile.changePassword.succesfulSubmittion') });
    };

    /** @param {MedForU.BaseAuthData} forgotPasswordData */
    const forgotPassword = async(forgotPasswordData) => {
        /** @type {import('axios').AxiosResponse<any>? } */
        const response = await medforuAPIAxios.post('/forgot-password', {
            ...forgotPasswordData, 'g-recaptcha-response': forgotPasswordData.gRecaptchaResponse,
        });

        if (!response) {
            return;
        }

        notify({
            text: getTranslation('forgotPassword.successText'),
            ms: 11000,
        });

        return true;
    };

    /** @param {MedForU.ResetPasswordPageParams} resetPasswordPageParams */
    const validateResetPasswordPage = async(resetPasswordPageParams) => {
        /** @type {import('axios').AxiosResponse<any>? } */
        const response = await medforuAPIAxios.get(RESET_PASSWORD_URL, { params: {
            email: resetPasswordPageParams.email,
            token: resetPasswordPageParams.token,
        } });

        if (!response) {
            router.push({ name: authRoutesConfig.lang.name });

            return;
        }

        const lang = getLangFromIso(resetPasswordPageParams.lang);

        await setLang(lang);
    };

    /** @param {MedForU.ResetPasswordData} resetPasswordData */
    const resetPassword = async(resetPasswordData) => {
        /** @type {import('axios').AxiosResponse<any>? } */
        const response = await medforuAPIAxios.post(RESET_PASSWORD_URL, {
            ...resetPasswordData, 'g-recaptcha-response': resetPasswordData.gRecaptchaResponse,
        });

        if (!response) {
            return;
        }

        router.push({ name: authRoutesConfig.login.name });
    };

    const refsObject = toRefs(userState);

    return {
        ...refsObject,
        initUserModule,
        login,
        signup,
        logout,
        changePassword,
        forgotPassword,
        validateResetPasswordPage,
        resetPassword,
    };
}

