import Vue from 'vue';
import './plugins/axios';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import './plugins/vueOffline';
import App from './App.vue';
import { medforuAPIAxios } from './utils/axios.util';
import globalMixins from './mixins/globalMixins';
import router from './router/index';
import useUsers from './modules/users.module';
import useSettings from './modules/settings.module';
import usePreSavedNeeds from './modules/preSavedNeeds.module';

Vue.config.productionTip = false;

const initConfigs = async() => {
    await Promise.all([
        medforuAPIAxios.get('/init'),
        useUsers().initUserModule(),
        useSettings().initSettingsModule(),
        usePreSavedNeeds().initPreSavedNeedsModule(),
    ]);
};

const initializeApp = async() => {
    await initConfigs();

    Vue.mixin(globalMixins);

    new Vue({
        vuetify,
        router,
        i18n,
        render: h => h(App),
    }).$mount('#app');

    /*
        NOTE: We ensure that rtl will work on initialization.
        Νormally, we should define init rtl in plugins/vuetify
    */
    vuetify.framework.rtl = useSettings().activeLang.value.isRtl;
};

initializeApp();
