import authConfig from './routes.config';
import { routeBasics } from '../../utils/routes.util';

export const authRoutes = {
    ...routeBasics(authConfig),
    component: () => import('../../views/layouts/layout.auth.vue'),
    children: [
        {
            ...routeBasics(authConfig.lang),
            component: () => import('../../views/auth/Lang.vue'),
        },
        {
            ...routeBasics(authConfig.base),
            component: () => import('../../views/auth/Base.vue'),
        },
        {
            ...routeBasics(authConfig.preSignup),
            component: () => import('../../views/auth/PreSignup.vue'),
        },
        {
            ...routeBasics(authConfig.login),
            component: () => import('../../views/auth/accounts/Login.vue'),
        },
        {
            ...routeBasics(authConfig.signup),
            component: () => import('../../views/auth/accounts/Signup.vue'),
        },
        {
            ...routeBasics(authConfig.resetPassword),
            component: () => import('../../views/auth/accounts/ResetPassword.vue'),
        },
        {
            ...routeBasics(authConfig.logout),
        },
    ],
};

