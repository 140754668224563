import localforage from 'localforage';
import moment from 'moment';
import { reactive, computed, toRefs } from 'vue';

/** @type {PreSavedNeedStoreKeyEnum['MedNeeds']} */
const PRE_SAVED_NEEDS_STORE_MED_NEEDS_KEY = 'medNeeds';
/** @type {PreSavedNeedStoreKeyEnum['HygProdNeeds']} */
const PRE_SAVED_NEEDS_STORE_HYG_PROD_NEEDS_KEY = 'hygProdNeeds';

const preSavedNeedsStore = localforage.createInstance({
    name: 'PreSavedNeedsStore',
});

/**
* @param {MedForU.HygProdNeedByCategory | MedForU.HygProdNeedByName | MedForU.MedNeedByName | MedForU.MedNeedBySubs} need1
* @param {MedForU.HygProdNeedByCategory | MedForU.HygProdNeedByName | MedForU.MedNeedByName | MedForU.MedNeedBySubs} need2
**/
const sortByDateUpdated = (need1, need2) => {
    return need2.dateUpdated - need1.dateUpdated ?? 0;
};

/**
* @param {Array<any>} items
* @param {string} prop
**/
const sumMethod = (items, prop) => {
    return items.reduce((a, b) => {
        return a + b[prop];
    }, 0);
};

/** @type {import('vue').UnwrapRef<MedForU.PreSavedNeedsState>} */
const preSavedNeedsState = reactive({
    medNeeds: [],
    hygProdNeeds: [],
    hasPreSavedNeeds: computed(() => preSavedNeedsState.medNeeds.length > 0 || preSavedNeedsState.hygProdNeeds.length > 0),
    preSavedNeedsTotalQuantity: computed(() => sumMethod(preSavedNeedsState.medNeeds, 'quantity') + sumMethod(preSavedNeedsState.hygProdNeeds, 'quantity')),
});

export default function usePreSavedNeeds() {
    const initPreSavedNeedsModule = async() => {
        const [medNeeds, hygProdNeeds] = await Promise.all([
            preSavedNeedsStore.getItem(PRE_SAVED_NEEDS_STORE_MED_NEEDS_KEY),
            preSavedNeedsStore.getItem(PRE_SAVED_NEEDS_STORE_HYG_PROD_NEEDS_KEY),
        ]);

        preSavedNeedsState.medNeeds = medNeeds || preSavedNeedsState.medNeeds;
        preSavedNeedsState.hygProdNeeds = hygProdNeeds || preSavedNeedsState.hygProdNeeds;
    };

    /**
    * @param {MedForU.HygProdNeedByCategory | MedForU.HygProdNeedByName | MedForU.MedNeedByName | MedForU.MedNeedBySubs} newNeed
    * @param {MedForU.HygProdNeedByCategory | MedForU.HygProdNeedByName | MedForU.MedNeedByName | MedForU.MedNeedBySubs | undefined} needAlreadyExisting
    * @param {Array<MedForU.HygProdNeedByCategory | MedForU.HygProdNeedByName | MedForU.MedNeedByName | MedForU.MedNeedBySubs>} preSavedMedOrHygProdNeeds
    * @param {PreSavedNeedStoreKey} storeKey
    **/
    const updateOrAddNewNeed = async(newNeed, needAlreadyExisting, preSavedMedOrHygProdNeeds, storeKey = PRE_SAVED_NEEDS_STORE_MED_NEEDS_KEY) => {
        const timeStamp = moment().valueOf();

        if (needAlreadyExisting) {
            needAlreadyExisting.quantity += newNeed.quantity;
            needAlreadyExisting.dateUpdated = timeStamp;
        } else {
            preSavedMedOrHygProdNeeds.push({ ...newNeed, dateCreated: timeStamp, dateUpdated: timeStamp });
        }

        preSavedMedOrHygProdNeeds.sort(sortByDateUpdated);

        await preSavedNeedsStore.setItem(storeKey, preSavedMedOrHygProdNeeds);
    };

    /** @param {MedForU.MedNeedByName} newMedNeedByName */
    const addMedNeedbyName = async(newMedNeedByName) => {
        newMedNeedByName.isByName = true;

        const medNeedByNameAlreadyΕxisting = preSavedNeedsState.medNeeds.find(needAlreadyExisting => needAlreadyExisting?.medEof === newMedNeedByName?.medEof);

        await updateOrAddNewNeed(newMedNeedByName, medNeedByNameAlreadyΕxisting, preSavedNeedsState.medNeeds);
    };

    /** @param {MedForU.MedNeedBySubs} newMedNeedbySubs */
    const addMedNeedbySubs = async(newMedNeedbySubs) => {
        newMedNeedbySubs.isByName = false;

        const needBySubsAlreadyΕxisting = preSavedNeedsState.medNeeds.find(needAlreadyExisting => {
            return needAlreadyExisting?.medEof === newMedNeedbySubs?.medEof && needAlreadyExisting?.concentration === newMedNeedbySubs?.concentration && needAlreadyExisting?.concentrationUnit === newMedNeedbySubs?.concentrationUnit;
        });

        await updateOrAddNewNeed(newMedNeedbySubs, needBySubsAlreadyΕxisting, preSavedNeedsState.medNeeds);
    };

    /** @param {MedForU.HygProdNeedByName} newHygProdNeedByName */
    const addHygProdNeedByName = async(newHygProdNeedByName) => {
        newHygProdNeedByName.isByName = true;

        const hygProdNeedByNameAlreadyΕxisting = preSavedNeedsState.hygProdNeeds.find(needAlreadyExisting => {
            return needAlreadyExisting?.id === newHygProdNeedByName?.id && needAlreadyExisting?.quantity_type === newHygProdNeedByName?.quantity_type;
        });

        newHygProdNeedByName.hygProd = newHygProdNeedByName.id;
        newHygProdNeedByName.hygProdName = newHygProdNeedByName.name;
        newHygProdNeedByName.subCategoryName = newHygProdNeedByName.sub_category_name;
        newHygProdNeedByName.quantityType = newHygProdNeedByName.quantity_type;

        await updateOrAddNewNeed(newHygProdNeedByName, hygProdNeedByNameAlreadyΕxisting, preSavedNeedsState.hygProdNeeds, PRE_SAVED_NEEDS_STORE_HYG_PROD_NEEDS_KEY);
    };

    /** @param {MedForU.HygProdNeedByCategory} newHygProdNeedByCategory */
    const addHygProdNeedByCategory = async(newHygProdNeedByCategory) => {
        newHygProdNeedByCategory.isByName = false;

        const hygProdNeedByCategoryAlreadyΕxisting = preSavedNeedsState.hygProdNeeds.find(needAlreadyExisting => {
            return needAlreadyExisting?.id === newHygProdNeedByCategory?.id && needAlreadyExisting?.quantity_type === newHygProdNeedByCategory?.quantity_type;
        });

        newHygProdNeedByCategory.category = newHygProdNeedByCategory.id;
        newHygProdNeedByCategory.categoryPresentation = newHygProdNeedByCategory.category_presentation;
        newHygProdNeedByCategory.quantityType = newHygProdNeedByCategory.quantity_type;

        await updateOrAddNewNeed(newHygProdNeedByCategory, hygProdNeedByCategoryAlreadyΕxisting, preSavedNeedsState.hygProdNeeds, PRE_SAVED_NEEDS_STORE_HYG_PROD_NEEDS_KEY);
    };

    /** @param {number} dateCreatedTimeStamp - dateCreatedTimeStamp is like an id since it is a number in ms and cannot be duplicated */
    const deletePreSavedMedNeed = async(dateCreatedTimeStamp) => {
        preSavedNeedsState.medNeeds = preSavedNeedsState.medNeeds.filter(need => need.dateCreated !== dateCreatedTimeStamp);

        await preSavedNeedsStore.setItem(PRE_SAVED_NEEDS_STORE_MED_NEEDS_KEY, preSavedNeedsState.medNeeds);
    };

    /** @param {number} dateCreatedTimeStamp - dateCreatedTimeStamp is like an id since it is a number in ms and cannot be duplicated */
    const deletePreSavedHygProdNeed = async(dateCreatedTimeStamp) => {
        preSavedNeedsState.hygProdNeeds = preSavedNeedsState.hygProdNeeds.filter(need => need.dateCreated !== dateCreatedTimeStamp);

        await preSavedNeedsStore.setItem(PRE_SAVED_NEEDS_STORE_HYG_PROD_NEEDS_KEY, preSavedNeedsState.hygProdNeeds);
    };

    const clearPreSavedNeedsState = async() => {
        preSavedNeedsState.medNeeds = [];
        preSavedNeedsState.hygProdNeeds = [];

        await Promise.all([
            preSavedNeedsStore.setItem(PRE_SAVED_NEEDS_STORE_MED_NEEDS_KEY, preSavedNeedsState.medNeeds),
            preSavedNeedsStore.setItem(PRE_SAVED_NEEDS_STORE_HYG_PROD_NEEDS_KEY, preSavedNeedsState.hygProdNeeds),
        ]);
    };

    const refsObject = toRefs(preSavedNeedsState);

    return {
        ...refsObject,
        initPreSavedNeedsModule,
        addMedNeedbyName,
        addMedNeedbySubs,
        addHygProdNeedByName,
        addHygProdNeedByCategory,
        deletePreSavedMedNeed,
        deletePreSavedHygProdNeed,
        clearPreSavedNeedsState,
    };
}
