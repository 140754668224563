<template>
    <v-app :style="{ background: getBackgroundBasedOnTheme }">
        <router-view />

        <generic-notification />

        <new-version-available-notification />

        <offline-notification />

        <ios-add-to-homescreen-notification />
    </v-app>
</template>

<script>
import genericNotification from './components/notifications/generic.vue';
import iosAddToHomescreenNotification from './components/notifications/iosAddToHomescreen.vue';
import offlineNotification from './components/notifications/offline.vue';
import newVersionAvailableNotification from './components/notifications/newVersionAvailable.vue';

export default {
    name: 'App',
    components: {
        genericNotification,
        iosAddToHomescreenNotification,
        offlineNotification,
        newVersionAvailableNotification,
    },
    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        },
        getBackgroundBasedOnTheme() {
            const routeBackground = this.$route?.meta?.background;
            return (
                // @ts-ignore
                this.$vuetify.theme.themes[this.theme][routeBackground] ??
                // @ts-ignore
                this.$vuetify.theme.themes[this.theme].background
            );
        },
    },
};
</script>

<style lang="scss">
@import "./scss/variables";

.v-application {
    font-family: $body-font-family, sans-serif !important;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .subtitle-1,
    .subtitle-2,
    .body-1,
    .body-2,
    .caption,
    .overline,
    .button,
    .title,
    .headline {
        font-family: $body-font-family, sans-serif !important;
    }
}

.grecaptcha-badge {
    display: none;
}

.v-list-item:nth-child(odd) {
    background-color: $background;
}
.v-select-list .v-list-item:nth-child(odd) {
    background-color: $lightGrayLike;
}

.v-list-item__title,
.v-list-item__subtitle {
    white-space: initial;
}

.v-autocomplete__content,
.v-menu__content {
    max-width: min-content;
    &::-webkit-scrollbar {
        width: 15px;
        background-color: $background;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $primary;
        outline: 1px solid slategrey;
    }
}

.needs-panel-card-title {
    border-top: 8px solid $secondary;
}

.needs-panel-card-title,
.needs-panel-card-more-info {
    border-bottom: 1px solid $primary !important;
}

.theme--light.v-skeleton-loader .v-skeleton-loader__card-heading,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-two-line {
    background: none;
}

.v-skeleton-loader__myCustomButton {
    width: 130px;
    height: 50px;
    background: rgba(0, 0, 0, 0.12);
}

.buttons_skeleton .v-skeleton-loader__card-heading {
    display: flex;
    justify-content: center;
}

.reduce_opacity {
    opacity: 0.9;
}

.v-application .v-btn--disabled {
    cursor: not-allowed !important;
    pointer-events: auto;
}
</style>
