// @ts-ignore
export function routeBasics(routeData) {
    const basics = {
        path: routeData?.path,
        name: routeData?.name,
    };

    if (routeData?.meta) {
        basics.meta = routeData.meta;
    }

    return basics;
}
