import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '../locale/en';
import el from '../locale/el';
import fa from '../locale/fa';
import fr from '../locale/fr';
import ar from '../locale/ar';
import uk from '../locale/uk';

Vue.use(VueI18n);

const messages = { el, en, fa, fr, ar, uk };

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE,
    fallbackLocale: process.env.VUE_APP_I18N_LOCALE,
    messages,
});

export default i18n;
