<template>
    <v-snackbar
        v-model="iosAddToHomescreen"
        top
        multi-line
        :timeout="-1"
        color="primary white--text"
    >
        {{ $t("iosInstallBanner.text") }}
        <template #action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="dismissIosAddToHomescreenNotification">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { defineComponent } from 'vue';
import useSettings from '../../modules/settings.module';

export default defineComponent({
    setup() {
        const { iosAddToHomescreen, dismissIosAddToHomescreenNotification } = useSettings();

        return {
            iosAddToHomescreen, dismissIosAddToHomescreenNotification,
        };
    },
});
</script>
