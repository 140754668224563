import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const vuetifyOptions = {
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#313693',
        secondary: '#33BFC3',
        background: '#F2EFF6',
        secondaryAlt: '#f06292',
        anchor: '#313693',
        orangeLike: '#E66E5A',
        blackText: '#444444',
        grayText: '#777777',
        lavenderLike: '#E6E7E8',
        lightGrayLike: '#ebebeb',
        statusBarColor: '#414092',

        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#E66E5A',
      },
      dark: {},
    },
  },
};

export default new Vuetify(vuetifyOptions);
